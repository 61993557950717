import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Checkbox, ListItemText, TextField } from "@mui/material";
import { getTherapyLogInfo, updateHomeProtocol } from "../../../Api";
import Swal from "sweetalert2";

function SessionFeedback() {
  const navigate = useNavigate();
  const { therapy_log_id } = useParams();
  const [content, setContent] = useState(null);
  const { role } = useOutletContext();

  const fetchTherapylogs = async () => {
    let apiData = await getTherapyLogInfo(therapy_log_id);
    if (apiData.status === "S") {
      apiData.result_info.activity_names =
        apiData.result_info.activity_names.filter(
          (activity) => activity !== null
        );
      setContent(apiData.result_info);
    }
  };

  const updateProtocol = async () => {
    let data = {
      home_workedon: content.home_workedon,
      home_observations: content.home_observations,
    };
    let apiRes = await updateHomeProtocol(data, content.therapy_log_id);
    if (apiRes.status === "S") {
      Swal.fire({
        text: "Session feedback updated successfully!",
        icon: "success",
        showConfirmButton: false,
        // timer: 3000
      }).then(() => {
        navigate("/app/sessions-feedback");
      });
    }
  };

  useEffect(() => {
    fetchTherapylogs();
  }, []);

  // useEffect(() => {
  //     // Retrieve Base64-encoded audio from localStorage
  //     const audioData = localStorage.getItem('recordedAudio');
  //     if (audioData) {
  //         // Convert Base64 to Blob
  //         const byteString = atob(audioData.split(',')[1]);
  //         const mimeString = audioData.split(',')[0].split(':')[1].split(';')[0];
  //         const buffer = new ArrayBuffer(byteString.length);
  //         const view = new Uint8Array(buffer);

  //         for (let i = 0; i < byteString.length; i++) {
  //             view[i] = byteString.charCodeAt(i);
  //         }

  //         const blob = new Blob([buffer], { type: mimeString });
  //         setAudioUrl(URL.createObjectURL(blob));
  //     }
  // }, []);

  return (
    <div className="editContainer">
      <h5 className="page-heading">Session Feedback</h5>
      {content && (
        <>
          <div className="row">
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Date"
                variant="outlined"
                autoComplete="off"
                required
                value={content.date}
                disabled
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Student name"
                variant="outlined"
                autoComplete="off"
                required
                value={content.student_name}
                disabled
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Therapy name"
                variant="outlined"
                autoComplete="off"
                required
                value={content.therapy_name}
                disabled
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Goal name"
                variant="outlined"
                autoComplete="off"
                required
                value={content.goal_name}
                disabled
              />
            </div>
            <div className="col-12 mb-3">
              {content.activity_names && content.activity_names.length > 0 && (
                <h6 className="mb-2">
                  <strong>Tasks</strong>
                </h6>
              )}
              {content.activity_names &&
                content.activity_names.length > 0 &&
                content.activity_names.map((activity) => (
                  <div
                    key={"activity" + activity.activity_id}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Checkbox disabled checked={true} />
                    <ListItemText primary={activity} />
                  </div>
                ))}
            </div>
            {content && content.comments && (
              <div className="col-12 mb-3">
                <TextField
                  className="w-100 cust-violet-input-field"
                  multiline
                  rows={4}
                  id="outlined-basic"
                  label="Comments"
                  variant="outlined"
                  autoComplete="off"
                  value={content.comments}
                  disabled
                />
              </div>
            )}
            <div className="col-12 mb-3">
              {/* {audioUrl ? ( */}
              <div>
                {content.audio_file_path && (
                  <>
                    <h6 className="mb-2">
                      <strong>Recorded Audio</strong>
                    </h6>
                    <audio
                      className="w-100 my-2"
                      controls
                      controlsList="nodownload"
                      src={
                        "https://impaxify.com/api/files/cdc/audio/" +
                        content.audio_file_path
                      }
                    />
                  </>
                )}
                {(content.home_workedon || content.home_observations) &&
                  role !== "4" && (
                    <>
                      <h6 className="mb-3">
                        <strong>Home Protocols</strong>
                      </h6>
                      {content.home_workedon && (
                        <>
                          <h6 className="mb-2">Actions</h6>
                          <TextField
                            className="w-100 cust-violet-input-field mt-1"
                            id="work-today"
                            label=""
                            variant="outlined"
                            autoComplete="off"
                            required
                            multiline
                            minRows={4}
                            value={content.home_workedon}
                            disabled
                          />
                        </>
                      )}
                      {content.home_observations && (
                        <>
                          <h6 className="mb-2 mt-3">Observations</h6>
                          <TextField
                            className="w-100 cust-violet-input-field mt-1"
                            id="work-today"
                            label=""
                            variant="outlined"
                            autoComplete="off"
                            required
                            multiline
                            minRows={4}
                            value={content.home_observations}
                            disabled
                          />
                        </>
                      )}
                    </>
                  )}
                {role === "4" && (
                  <>
                    <h6 className="mb-3">
                      <strong>Home Protocols</strong>
                    </h6>
                    <h6 className="mb-2">Actions</h6>
                    <TextField
                      className="w-100 cust-violet-input-field mt-1"
                      id="work-today"
                      label=""
                      variant="outlined"
                      autoComplete="off"
                      required
                      multiline
                      minRows={4}
                      value={content.home_workedon}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        const invalidPattern =
                          /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                        if (!invalidPattern.test(value)) {
                          setContent({
                            ...content,
                            home_workedon: value,
                          });
                        }
                      }}
                    />
                    <h6 className="mb-2 mt-2">Observations</h6>
                    <TextField
                      className="w-100 cust-violet-input-field mt-1"
                      id="work-today"
                      label=""
                      variant="outlined"
                      autoComplete="off"
                      required
                      multiline
                      minRows={4}
                      value={content.home_observations}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        const invalidPattern =
                          /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                        if (!invalidPattern.test(value)) {
                          setContent({
                            ...content,
                            home_observations: value,
                          });
                        }
                      }}
                    />
                  </>
                )}
              </div>
              {/* ) : null} */}
            </div>

            {/* } */}
          </div>
          <div className="row buttonsRow d-flex px-3">
            {role === "4" && (
              <button
                className="createButton flex-1 me-3"
                onClick={() => {
                  updateProtocol();
                }}
              >
                Update
              </button>
            )}
            <button
              className="cancelButton flex-1"
              onClick={() => {
                navigate("/app/sessions-feedback");
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default SessionFeedback;
