import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import "./profile.scss"
import { getTherapies, getTherapistInfo, updateTherapist } from '../../Api';
import Swal from 'sweetalert2';
import MobileFooter from '../mobile-footer/MobileFooter';
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    width: '100%',
    color: '#000',
    '&.Mui-focused': {
        color: '#000',
    },
}));
const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8267ed',
    },
}));
function MobileTherapistProfile() {
    const navigate = useNavigate();
    const [selectedTherapies, setSelectedTherapies] = useState([]);
    const [therapies, setTherapies] = useState([]);

    const [content, setContent] = React.useState(null);

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        console.log(userData)

        if (userData.therapist_correl_id) {
            let apiData = await getTherapistInfo(userData.therapist_correl_id);

            if (apiData.status === "S") {
                setContent(apiData.result_info);
                setSelectedTherapies(apiData.result_info.specializations.map((therapy) => therapy.therapy_id))
            } else {
                setContent(null);
                setSelectedTherapies([]);
            }
        }
        let therapiesData = await getTherapies(userData.account_code);
        if (therapiesData.status === "S") {
            setTherapies(therapiesData.result_info);
        } else {
            setTherapies([])
        }
    }
    const update = async (therapist_id) => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        if (!content.therapist_name) {
            Swal.fire({
                text: 'Please enter the therapist name',
                icon: 'warning',
                showConfirmButton: true,
            });
            return;
        } else if (!content.therapist_name.length > 255) {
            Swal.fire({
                text: 'Therapist name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: true,
            });
            return; 
        }
        let apiData = await updateTherapist({
            account_code: userData.account_code,
            therapist_correl_id: content.therapist_correl_id,
            therapist_name: content.therapist_name,
            email: content.email,
            phone: content.phone,
            specialization: selectedTherapies.join(","),
        }, therapist_id);
        console.log(apiData);
        if (apiData.status === "S") {
            Swal.fire({
                text: 'Profile updated successfully',
                showConfirmButton: false,
                icon: 'success',
                timer: 3000,
            }).then(() => {
               navigate('/app/dashboard');
            })
        } else if (apiData.status === "E" && apiData.result_code === 404) {
            Swal.fire({
              text: "Account not found",
              showConfirmButton: false,
              icon: "warning",
              timer: 3000,
            }).then(() => {
              navigate("/app/dashboard");
            });
        } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        } else {
            Swal.fire({
                text: JSON.stringify(apiData),
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            {content &&
                <div className='therapistprofilepage'>
                    <div className="bg-color justify-content-center align-items-center">

                        <div className='text-center'>
                            <h5 className="page-heading text-white m-0">Profile</h5>
                        </div>
                    </div>
                    <div className='contentContainer mt-2 d-flex flex-column'>
                        <div className="row mb-lg-3">
                            <div className="col-12 col-lg-6 mb-3">
                                <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Name" variant="outlined" autoComplete="off" required
                                    value={content.therapist_name || ''} onChange={(ev) => {
                                        const value = ev.target.value;
                                        const invalidPattern =
                                          /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                                        if (!invalidPattern.test(value)) {
                                          setContent({
                                            ...content,
                                            therapist_name: value,
                                          });
                                        }
                                    }} />
                            </div>
                            <div className="col-12 col-lg-6 mb-3">
                                <FormControl fullWidth>
                                    <CustomInputLabel id="demo-multiselect-label" disabled>Specialization</CustomInputLabel>
                                    <CustomSelect
                                        labelId="demo-multiselect-label"
                                        id="demo-multiselect"
                                        multiple
                                        disabled
                                        value={selectedTherapies}
                                        onChange={(event) => setSelectedTherapies(event.target.value)}
                                        label="Specialization"
                                        renderValue={(selected) =>
                                            selected.map((id) => {
                                                const therapy = therapies.find((therapy) => therapy.therapy_id === id);
                                                return therapy ? therapy.therapy_name : '';
                                            }).join(', ')
                                        }
                                    >
                                        {therapies.map((therapy) => (
                                            <MenuItem key={therapy.therapy_id} value={therapy.therapy_id}>
                                                <Checkbox checked={selectedTherapies.includes(therapy.therapy_id)} />
                                                <ListItemText primary={therapy.therapy_name} />
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row mb-lg-3">
                            <div className="col-12 col-lg-6 mb-3">
                                <TextField className='w-100' id="outlined-basic" label="Mobile" variant="outlined" autoComplete="off" required
                                    value={content.phone} disabled />
                            </div>
                            <div className="col-12 col-lg-6 mb-3">
                                <TextField className='w-100' id="outlined-basic" label="Email" variant="outlined" autoComplete="off" required value={content.email} disabled />
                            </div>
                        </div>
                        <div className="buttonsRow d-flex mt-auto mb-2">
                            <button className='createButton flex-1'
                                onClick={() => { update(content.therapist_id) }}
                            >Update</button>
                            {/* <button className='cancelButton' onClick={() => { navigate('/app/manage-therapists') }}>Cancel</button> */}
                        </div>
                    </div>
                    <div className='footer-pad'>
                        <MobileFooter />
                    </div>
                </div>}
        </>
    )
}

export default MobileTherapistProfile
