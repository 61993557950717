import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./createstudent.scss";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { createStudent, getTherapies } from "../../../Api";
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  width: "100%",
  color: "#000",
  "&.Mui-focused": {
    color: "#000",
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#8267ed",
  },
}));
const ColoredCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.main,
  "&.Mui-checked": {
    color: "#8267ed",
    borderColor: "#8267ed",
  },
}));
function CreateStudent() {
  const navigate = useNavigate();
  const [selectedTherapies, setSelectedTherapies] = useState([]);
  const [therapies, setTherapies] = useState([]);

  const [content, setContent] = React.useState({
    student_name: "",
    student_dob: null,
    parent_name: "",
    parent_mobile: "",
    parent_email: "",
    specialization: [],
    fees: "",
    notes: "",
  });

  const handleTherapyChange = (event) => {
    const { value } = event.target;
    setSelectedTherapies(value);
    console.log(value);
    const selectedTherapy = value.map((therapy) => therapy.therapy_id);
    setContent({ ...content, specialization: selectedTherapy });
  };

  const checkTextInput = (ev, updateField) => {
    const invalidPattern = /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
    if (invalidPattern.test(ev.target.value)) {
    } else {
      let newContent = { ...content };
      newContent[updateField] = ev.target.value;
      setContent({ ...newContent });
    }
  };

  const submit = async () => {
    Swal.fire({
      html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
      showConfirmButton: false,
      heightAuto: false,
      customClass: { popup: "loader-swal" },
    });
    let emalval = new RegExp(
      "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,4}$"
    );
    if (!content.student_name) {
      Swal.fire({
        text: "Please provide Student name!",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.student_name.length > 255) {
      Swal.fire({
        text: "Student name should be less than 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.student_name.length > 255) {
      Swal.fire({
        text: "Student name should be less than 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (!content.student_dob) {
      Swal.fire({
        text: "Please select Date of Birth",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (!content.parent_name) {
      Swal.fire({
        text: "Please provide Parent Name!",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.parent_name.length > 255) {
      Swal.fire({
        text: "Parent name should be less than 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (!content.parent_mobile) {
      Swal.fire({
        text: "Please provide Mobile number",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.parent_mobile.length < 10) {
      Swal.fire({
        text: "Mobile Number should not be less than 10 digits",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.parent_mobile.length > 255) {
      Swal.fire({
        text: "Mobile Number should be less than 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (!content.parent_email) {
      Swal.fire({
        text: "Please enter Email",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.parent_email && !emalval.test(content.parent_email)) {
      Swal.fire({
        text: "Please enter valid Email",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.parent_email.length > 255) {
      Swal.fire({
        text: "Parent email should be less than 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (!content.fees) {
      Swal.fire({
        text: "Please enter fees",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (selectedTherapies.length === 0) {
      Swal.fire({
        text: "Please select therapies",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else {
      let data = { ...content };
      if (data.parent_email) {
        data.parent_email = data.parent_email.toLowerCase();
      }
      data.enrolled_therapies = data.specialization.join(",");
      let userData = JSON.parse(localStorage.getItem("userData") || "{}");
      data.account_code = userData.account_code;
      console.log(data);
      let apiData = await createStudent({ ...data });
      console.log(apiData);
      if (apiData.status === "S") {
        Swal.fire({
          text: "Student created successfully",
          showConfirmButton: false,
          icon: "success",
          timer: 3000,
        }).then(() => {
          navigate("/app/manage-students");
        });
      }
      if (apiData.status === "E" && apiData.status_code === "300") {
        Swal.fire({
          text: "Email is already taken",
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
      }
      if (apiData.status === "E" && apiData.status_code === "302") {
        Swal.fire({
          text: "License limit exceeded!",
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
      } else if (apiData.status === "E") {
        Swal.fire({
          text: apiData.message,
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
      }
      if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Missing token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      }
      if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Invalid token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      let userData = JSON.parse(localStorage.getItem("userData") || "{}");
      let acc_code = userData.account_code;
      let apiData = await getTherapies(acc_code);
      apiData.status === "S"
        ? setTherapies(apiData.result_info)
        : setTherapies([]);
      if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Missing token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      }
      if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Invalid token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      }
    })();
  }, [navigate]);

  return (
    <>
      <div className="createContainer">
        <h5 className="page-heading">Create Student</h5>
        <div className="row mb-lg-3">
          <div className="col-12 col-lg-6 mb-3">
            <TextField
              className="w-100 cust-violet-input-field"
              id="outlined-basic"
              label="Name"
              variant="outlined"
              autoComplete="off"
              required
              value={content.student_name}
              onChange={(ev) => {
                const value = ev.target.value;
                const invalidPattern =
                  /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                if (!invalidPattern.test(value)) {
                  setContent({ ...content, student_name: value });
                }
              }}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Birth *"
                className="w-100 cust-violet-input-field"
                required
                value={content.student_dob ? dayjs(content.student_dob) : null}
                maxDate={dayjs(new Date())}
                onChange={(ev) => {
                  let date = ev?.$d;
                  setContent({
                    ...content,
                    student_dob: dayjs(date).format("YYYY-MM-DD"),
                  });
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="row mb-lg-3">
          <div className="col-12 col-lg-6 mb-3">
            <TextField
              className="w-100 cust-violet-input-field"
              id="outlined-basic"
              label="Parent Name"
              variant="outlined"
              autoComplete="off"
              required
              value={content.parent_name}
              onChange={(ev) => {
                const value = ev.target.value;
                const invalidPattern =
                  /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                if (!invalidPattern.test(value)) {
                  setContent({ ...content, parent_name: value });
                }
              }}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <TextField
              className="w-100 cust-violet-input-field"
              id="outlined-basic"
              label="Mobile"
              variant="outlined"
              autoComplete="off"
              required
              value={content.parent_mobile}
              onChange={(ev) => {
                const value = ev.target.value;
                const mobilePattern =
                  /^\+?(\d{1,4})?[-.\s]?(\d{0,3})[-.\s]?(\d{0,4})[-.\s]?(\d{0,4})$/;
                if (value && mobilePattern.test(value)) {
                  setContent({ ...content, parent_mobile: value });
                } else if (!value) {
                  setContent({ ...content, parent_mobile: "" });
                }
              }}
              maxLength={15}
            />
          </div>
        </div>
        <div className="row mb-lg-3">
          <div className="col-12 col-lg-6 mb-3">
            <TextField
              className="w-100 cust-violet-input-field"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              autoComplete="off"
              required
              value={content.parent_email}
              onChange={(ev) => {
                const value = ev.target.value;
                const emailPattern = /^[a-zA-Z0-9._%+-]+(@[a-zA-Z0-9.-]*)?$/;
                if (value && emailPattern.test(value)) {
                  setContent({ ...content, parent_email: value });
                } else if (!value) {
                  setContent({ ...content, parent_email: "" });
                }
              }}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <TextField
              className="w-100 cust-violet-input-field"
              id="outlined-basic"
              label="Fees"
              variant="outlined"
              autoComplete="off"
              required
              value={content.fees}
              onChange={(ev) => {
                const value = ev.target.value;
                if (!isNaN(value)) {
                  setContent({ ...content, fees: value });
                }
              }}
              inputProps={{
                min: 0,
                maxLength: 9, // Set maxLength here
              }}
            />
          </div>
        </div>
        <div className="row mb-lg-3">
          <div className="col-12 mb-3">
            <FormControl fullWidth required>
              <CustomInputLabel id="demo-multiselect-label">
                Enrolled Therapies
              </CustomInputLabel>
              <CustomSelect
                labelId="demo-multiselect-label"
                id="demo-multiselect"
                required
                multiple
                value={selectedTherapies}
                onChange={handleTherapyChange}
                label="Enrolled Therapies"
                renderValue={(selected) => {
                  let name = "";
                  selected.forEach((val) => {
                    name = name
                      ? name + ", " + val.therapy_name
                      : name + val.therapy_name;
                  });
                  return name;
                }}
              >
                {therapies.map((therapy) => (
                  <MenuItem key={therapy.therapy_id} value={therapy}>
                    <ColoredCheckbox
                      checked={selectedTherapies.some(
                        (selected) => selected.therapy_id === therapy.therapy_id
                      )}
                    />
                    <ListItemText primary={therapy.therapy_name} />
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 mb-3">
            <TextField
              className="w-100 cust-violet-input-field"
              id="outlined-basic"
              label="Notes"
              variant="outlined"
              multiline
              rows={3}
              autoComplete="off"
              value={content.notes}
              onChange={(ev) => {
                const value = ev.target.value;
                const invalidPattern =
                  /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                if (!invalidPattern.test(value)) {
                  setContent({ ...content, notes: value });
                }
              }}
            />
          </div>
        </div>
        <div className="row buttonsRow">
          <button
            className="createButton me-3"
            onClick={() => {
              submit();
            }}
          >
            Create
          </button>
          <button
            className="cancelButton"
            onClick={() => {
              navigate("/app/manage-students");
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default CreateStudent;
