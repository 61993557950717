import React, { useState, useRef } from "react";
import Lame from "lamejs";
import "./audiorecorder.scss";
import { FaMicrophone, FaStop, FaTrash } from "react-icons/fa";

const AudioRecorder = ({mode  = 'create', setRecordedFile}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null); // Separate state for audio URL
  const mediaRecorderRef = useRef(null);
  const timerRef = useRef(null);
  const audioChunks = useRef([]);

  const startRecording = async () => {
    try {
      // Check for MediaRecorder support
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        alert("Your browser does not support audio recording.");
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mimeType = "audio/mp4";
      // MediaRecorder.isTypeSupported("audio/mp4")
      //   ? "audio/mp4"
      //   : MediaRecorder.isTypeSupported("audio/wav")
      //   ? "audio/wav"
      //   : "audio/webm";

      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });

      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );

      mediaRecorderRef.current.start();
      setIsRecording(true);
      startTimer();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      stopTimer();
    }
  };

  const deleteRecording = () => {
    setAudioBlob(null);
    setAudioUrl(null);
    setRecordingTime(0);
  };

  const handleDataAvailable = async (event) => {
    if (event.data.size > 0) {
      audioChunks.current.push(event.data);

      // Combine chunks into a single Blob (audio/mp4)
      const mp3Blob = new Blob(audioChunks.current, { type: "audio/mp4" });
      // const mp3Blob = await convertToMp3(mp4Blob);

      setAudioBlob(mp3Blob);

      // Generate URL for playback
      const url = URL.createObjectURL(mp3Blob);
      setAudioUrl(url);

      // Create MP3 File
      const audioFile = new File([mp3Blob], "recorded_audio.mp4", {
        type: "audio/mp4",
      });
      console.log(audioFile)
      // setRecordedFile(audioFile);
      setRecordedFile(audioFile)
    }
  };

  const convertToMp3 = async (mp4Blob) => {
    const arrayBuffer = await mp4Blob.arrayBuffer();
    const audioData = new Uint8Array(arrayBuffer);

    const mp3Encoder = new Lame.Mp3Encoder(1, 44100, 128); // Mono, 44.1kHz, 128kbps
    const mp3Data = [];

    // Chunk encoding for better performance
    const samplesPerChunk = 1152;
    for (let i = 0; i < audioData.length; i += samplesPerChunk) {
      const chunk = audioData.subarray(i, i + samplesPerChunk);
      const mp3Buffer = mp3Encoder.encodeBuffer(chunk);
      if (mp3Buffer.length > 0) {
        mp3Data.push(mp3Buffer);
      }
    }

    // Finalize MP3 file
    const mp3End = mp3Encoder.flush();
    if (mp3End.length > 0) {
      mp3Data.push(mp3End);
    }

    // Create MP3 Blob
    return new Blob(mp3Data, { type: "audio/mp3" });
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="col-12 col-lg-6 audio-recorder-page">
      {!isRecording && !audioBlob && (
        <div className="d-flex justify-content-between violet-bg">
          <h6 className="mb-0 text-uppercase">Record</h6>
          <div onClick={() => {startRecording()}} className="iconContainer">
            <FaMicrophone />
          </div>
        </div>
      )}
      {isRecording && (
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center violet-bg justify-content-between w-100">
            <h6 className="mb-0 text-uppercase">Stop</h6>
            <div onClick={() => {stopRecording()}} className="stopiconContainer">
              <FaStop fontSize={10} />
            </div>
          </div>
          <span className="text-muted ms-2">{formatTime(recordingTime)}</span>
        </div>
      )}
      {audioBlob && (
        <>
          <h6 className="py-2">Recorded Audio</h6>
          <div className="d-flex align-items-center">
            {audioUrl ? (
              <audio
                src={audioUrl}
                controlsList="nodownload"
                controls
                className="w-100"
              />
            ) : (
              <p className="text-muted">Processing audio...</p>
            )}
            <div onClick={deleteRecording}>
              <FaTrash className="ms-3 cursor-pointer" color="#8267ed" />
            </div>
          </div>
          <p className="py-2 text-muted">
            Playback Time {formatTime(recordingTime)}
          </p>
        </>
      )}
    </div>
  );
};

export default AudioRecorder;