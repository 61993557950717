import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./creategoals.scss";
import { styled } from "@mui/material/styles";
import { ArrowBack, Delete } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Swal from "sweetalert2";
import { createGoal, getTherapies } from "../../../Api";
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  width: "100%",
  color: "#000",
  "&.Mui-focused": {
    color: "#000",
  },
}));
const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#8267ed",
  },
}));
function CreateGoalsActivities() {
  const navigate = useNavigate();
  const [activities, setActivities] = useState([]);
  const [therapies, setTherapies] = useState([]);
  const [content, setContent] = React.useState({
    goal_name: "",
    goal_description: "",
    therapy_id: "",
  });

  const addActivity = () => {
    setActivities([...activities, { name: "" }]);
  };

  const deleteActivity = (index) => {
    const updatedActivities = activities.filter((_, i) => i !== index);
    setActivities(updatedActivities);
  };

  const submit = async () => {
    if (!content.goal_name) {
      Swal.fire({
        text: "Please provide goal name",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.goal_name.length > 255) {
      Swal.fire({
        text: "Goal name should be lessthan 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (!content.therapy_id) {
      Swal.fire({
        text: "Please select Therapy",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (activities.length === 0) {
      Swal.fire({
        text: "Please add a task",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (
      activities.filter((activity) => activity.name === "").length > 0
    ) {
      Swal.fire({
        text: "Please enter name for task",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (
      activities.filter((activity) => activity.name.length > 255).length > 0
    ) {
      Swal.fire({
        text: "Task name should be less than 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else {
      let userData = JSON.parse(localStorage.getItem("userData") || "{}");
      let formData = {
        ...content,
        account_code: userData.account_code,
        activities: [...activities.map((activity) => activity.name)],
      };
      console.log(formData);
      let apiData = await createGoal(formData);
      console.log(apiData);
      if (apiData.status === "S") {
        Swal.fire({
          text: "Goal created successfully",
          showConfirmButton: false,
          icon: "success",
          timer: 3000,
        }).then(() => {
          navigate("/app/manage-goals-activities");
        });
      }
      if (apiData.status === "E") {
        Swal.fire({
          text: apiData.message,
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
      }
      if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Missing token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      }
      if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Invalid token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      let userData = JSON.parse(localStorage.getItem("userData") || "{}");
      let acc_code = userData.account_code;
      let apiData = await getTherapies(acc_code);
      apiData.status === "S"
        ? setTherapies(apiData.result_info)
        : setTherapies([]);
      if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Missing token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      }
      if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Invalid token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      }
    })();
  }, [navigate]);
  const handleGoBack = () => {
    navigate(-1); // Equivalent to history.goBack()
  };
  return (
    <div className="createContainer">
      <h5 className="page-heading"> Create Goals</h5>
      <div className="row mb-lg-3">
        <div className="col-12 mb-3">
          <TextField
            className="w-100 cust-violet-input-field"
            id="outlined-basic"
            label="Name"
            variant="outlined"
            autoComplete="off"
            required
            value={content.goal_name}
            onChange={(ev) => {
              const value = ev.target.value;
              const invalidPattern = /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
              if (!invalidPattern.test(value)) {
                setContent({
                  ...content,
                  goal_name: value,
                });
              }
            }}
          />
        </div>
      </div>
      <div className="row mb-lg-3">
        <div className="col-12 mb-3">
          <TextField
            className="w-100 cust-violet-input-field"
            id="outlined-basic"
            label="Description"
            multiline
            rows={3}
            variant="outlined"
            autoComplete="off"
            value={content.goal_description}
            onChange={(ev) => {
              const value = ev.target.value;
              const invalidPattern = /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
              if (!invalidPattern.test(value)) {
                setContent({
                  ...content,
                  goal_description: value,
                });
              }
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 mb-3">
          <FormControl fullWidth required>
            <CustomInputLabel id="demo-simple-select-label">
              Therapy
            </CustomInputLabel>
            <CustomSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={content.therapy_id}
              label="Therapy"
              onChange={(ev) =>
                setContent({ ...content, therapy_id: ev.target.value })
              }
            >
              {therapies &&
                therapies.map((therapy) => (
                  <MenuItem
                    key={"therapy-" + therapy.therapy_id}
                    value={therapy.therapy_id}
                  >
                    {therapy.therapy_name}
                  </MenuItem>
                ))}
            </CustomSelect>
          </FormControl>
        </div>
        <div className="col-12">
          {activities &&
            activities.length > 0 &&
            activities.map((activity, index) => (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mt-3">Task {index + 1}</h5>
                  <Delete
                    className="text-danger"
                    onClick={() => {
                      deleteActivity(index);
                    }}
                  />
                </div>
                <div>
                  <TextField
                    className="w-100 mt-2 mb-3 cust-violet-input-field"
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    autoComplete="off"
                    required
                    value={activity.name}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      const invalidPattern =
                        /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                      if (!invalidPattern.test(value)) {
                        let newActivities = [...activities].map(
                          (mapAc, mapInd) => {
                            if (mapInd === index) {
                              mapAc.name = ev.target.value;
                            }
                            return mapAc;
                          }
                        );
                        setActivities(newActivities);
                      }
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="col-12 mt-3 text-end">
          <button className="createButton" onClick={addActivity}>
            Add Task
          </button>
        </div>
      </div>

      <div className="row buttonsRow">
        <button
          className="createButton me-3"
          onClick={() => {
            submit();
          }}
        >
          Create
        </button>
        <button
          className="cancelButton"
          onClick={() => {
            navigate("/app/manage-goals-activities");
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default CreateGoalsActivities;
