import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./edittherapist.scss";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  updateTherapist,
  getTherapies,
  getTherapistInfo,
  deleteTherapist,
} from "../../../Api";
import { ArrowBack } from "@mui/icons-material";
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  width: "100%",
  color: "#000",
  "&.Mui-focused": {
    color: "#000",
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    color: "#000",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#8267ed",
    color: "#8267ed",
  },
}));
const ColoredCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.main,
  "&.Mui-checked": {
    color: "#8267ed",
    borderColor: "#8267ed",
  },
}));
function EditTherapists() {
  const navigate = useNavigate();
  const { therapist_correl_id } = useParams();

  const [selectedTherapies, setSelectedTherapies] = useState([]);
  const [therapies, setTherapies] = useState([]);

  const [userInfo, setUserInfo] = useState(null);
  const [content, setContent] = React.useState(null);

  const fetchData = async () => {
    let userData = JSON.parse(localStorage.getItem("userData") || "{}");
    setUserInfo(userData);
    let apiData = await getTherapistInfo(therapist_correl_id);

    if (apiData.status === "S") {
      setContent(apiData.result_info);
      setSelectedTherapies(
        apiData.result_info.specializations.map((therapy) => therapy.therapy_id)
      );
    } else {
      setContent(null);
    }

    let therapiesData = await getTherapies(userData.account_code);
    if (therapiesData.status === "S") {
      setTherapies(therapiesData.result_info);
    } else {
      setTherapies([]);
    }
  };
  const update = async (therapist_id) => {
    let userData = JSON.parse(localStorage.getItem("userData") || "{}");
    let emalval = new RegExp(
      "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
    );
    if (!content.therapist_name) {
      Swal.fire({
        text: "Please provide Therapist name!",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.therapist_name.length > 255) {
      Swal.fire({
        text: "Therapist name should be less than 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (selectedTherapies.length === 0) {
      Swal.fire({
        text: "Please select specialization",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (!content.phone) {
      Swal.fire({
        text: "Please enter Mobile number",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.phone.length > 255) {
      Swal.fire({
        text: "Mobile number should be less than 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.email.length > 255) {
      Swal.fire({
        text: "Email should be less than 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.email && !emalval.test(content.email)) {
      Swal.fire({
        text: "Please enter valid Email",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else {
      let apiData = await updateTherapist(
        {
          account_code: userData.account_code,
          therapist_correl_id: content.therapist_correl_id,
          therapist_name: content.therapist_name,
          email: content.email,
          phone: content.phone,
          specialization: selectedTherapies.join(","),
        },
        therapist_id
      );
      console.log(apiData);
      if (apiData.status === "S") {
        Swal.fire({
          text: "Therapist updated successfully",
          showConfirmButton: false,
          icon: "success",
          timer: 3000,
        }).then(() => {
          navigate("/app/manage-therapists");
        });
      } else if (apiData.status === "E" && apiData.result_code === 404) {
        Swal.fire({
          text: "Therapist not found",
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        }).then(() => {
          navigate("/app/manage-therapists");
        });
      } else if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Missing token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      } else if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Invalid token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      } else {
        Swal.fire({
          text: JSON.stringify(apiData),
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {content && (
        <div className="editContainer d-none d-lg-block">
          <h5 className="page-heading">Edit Therapists</h5>

          <div className="row mb-lg-3">
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Name"
                variant="outlined"
                autoComplete="off"
                required
                value={content.therapist_name || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setContent({
                      ...content,
                      therapist_name: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <FormControl fullWidth required>
                <CustomInputLabel id="demo-multiselect-label">
                  Specialization
                </CustomInputLabel>
                <CustomSelect
                  labelId="demo-multiselect-label"
                  id="demo-multiselect"
                  multiple
                  value={selectedTherapies}
                  onChange={(event) => setSelectedTherapies(event.target.value)}
                  label="Specialization"
                  renderValue={(selected) =>
                    selected
                      .map((id) => {
                        const therapy = therapies.find(
                          (therapy) => therapy.therapy_id === id
                        );
                        return therapy ? therapy.therapy_name : "";
                      })
                      .join(", ")
                  }
                >
                  {therapies.map((therapy) => (
                    <MenuItem
                      key={therapy.therapy_id}
                      value={therapy.therapy_id}
                    >
                      <ColoredCheckbox
                        checked={selectedTherapies.includes(therapy.therapy_id)}
                      />
                      <ListItemText primary={therapy.therapy_name} />
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </div>
          </div>
          <div className="row mb-lg-3">
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Mobile"
                variant="outlined"
                autoComplete="off"
                required
                disabled
                value={content.phone}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                autoComplete="off"
                required
                disabled
                value={content.email}
              />
            </div>
          </div>
          <div className="row buttonsRow">
            <button
              className="createButton me-3"
              onClick={() => {
                update(content.therapist_id);
              }}
            >
              Update
            </button>
            <button
              className="cancelButton"
              onClick={() => {
                navigate("/app/manage-therapists");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default EditTherapists;
