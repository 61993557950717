import React, { useCallback, useEffect, useState } from 'react'
import "./sessions.scss"
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ChevronRight, ControlPoint } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MobileFooter from '../../../components/mobile-footer/MobileFooter';
import { deleteTherapyLog, getAccounttherapylogsbydate, getMonthlyTherapyLogs, getStudentDatewiseTherapyLogs, getStudentMonthlyTherapyLogs, getTherapistLogsByDate, getTherapistMonthlyTherapyLogs } from '../../../Api';

function MobileSessionsFeedback() {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [totalPages, setTotalPages] = useState(null);
    const [content, setContent] = useState([]);
    const [activeTab, setActiveTab] = useState('today');
    const [role, setRole] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const [selectedYear, setSelectedYear] = useState(dayjs().year());

    const filteredData = [...content].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);;

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const edit = therapy_log_id => {
        navigate('/app/edit-session-feedback/' + therapy_log_id);
    };

    const view = therapy_log_id => {
        navigate('/app/view-session-feedback/' + therapy_log_id);
    };

    const delSession = therapy_log_id => {
        Swal.fire({
            text: 'Are you sure you want to delete the Therapy Log?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async res => {
            if (res.isConfirmed) {
                let apiRes = await deleteTherapyLog(therapy_log_id);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: 'Therapy log Deleted',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        // fetchData();
                    });
                }
            }
        });
    };

    const fetchRecords = useCallback(async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let userRole = JSON.parse(localStorage.getItem('role'));
        setRole(userRole);
        let apiRes;
        if (activeTab === 'today') {
            let currentDate = new Date();
            let formattedDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()

            if (+userRole === 2) {
                apiRes = await getAccounttherapylogsbydate(userData.account_code, formattedDate);
            }
            if (+userRole === 3) {
                apiRes = await getTherapistLogsByDate(userData.account_code, userData.therapist_id, formattedDate);
            }
            if (+userRole === 4) {
                apiRes = await getStudentDatewiseTherapyLogs(userData.account_code, userData.student_id, formattedDate);
            }
        }
        if (activeTab === 'monthly') {
            const month = selectedMonth.month();
            const year = selectedMonth.year();
            if (+userRole === 2) {
                apiRes = await getMonthlyTherapyLogs(userData.account_code, month, year);
            }
            if (+userRole === 3) {
                apiRes = await getTherapistMonthlyTherapyLogs(userData.account_code, userData.therapist_id, month, year)
            }
            if (+userRole === 4) {
                apiRes = await getStudentMonthlyTherapyLogs(userData.account_code, userData.student_id, month, year)
            }
        }
        let data = await apiRes;
        console.log(data);

        if (data.status === 'S') {
            setContent(data.result_info);
            setTotalPages(Math.ceil(data.result_info.length / pageSize));
        } else if (data.result_code === 404) {
            setContent([]);
            setTotalPages(0);
        } else {
            setContent([]);
            setTotalPages(0);
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong!',
            });
        }

    }, [activeTab, selectedMonth, selectedYear]);

    useEffect(() => {
        fetchRecords()
    }, [fetchRecords])

    return (
        <div className='mobile-managesessions'>
            <div className='header-container'>
                {+role === 3 && (
                    <Link to="/app/create-session-feedback" className='text-decoration-none'>
                        <ControlPoint className='create' />
                    </Link>
                )}
                <div className='heading-div'>
                    <h5 className='page-heading text-white'>Sessions Feedback</h5>
                </div>
            </div>
            <div className='contentContainer mt-3'>
                <div className='d-flex'>
                    <button
                        className={`tab-button flex-1 ${activeTab === 'today' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveTab('today');
                        }}
                    >
                        Today
                    </button>
                    <button
                        className={`tab-button flex-1 ${activeTab === 'monthly' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveTab('monthly');
                        }}
                    >
                        Monthly
                    </button>
                </div>
                {activeTab === 'monthly' && (
                    <div className='mt-3 mb-4'>
                        <div className='d-flex align-items-center'>
                            {activeTab === 'monthly' && (
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker label={'Month and Year'} views={['month', 'year']}
                                        className='cust-violet-input-field w-100'
                                        value={selectedMonth}
                                        onChange={(newValue) => setSelectedMonth(newValue)} />
                                </LocalizationProvider>
                            )}
                        </div>
                    </div>
                )}

                {paginatedData && paginatedData.length > 0 ? (
                    paginatedData.map((row, index) => (
                        <div className="card mobile-card mt-3" onClick={() => edit(row.therapy_log_id)} key={'log-'+row.therapy_log_id}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0 card-content'>{row.student_name}</h6>
                                <ChevronRight className='iconColor' />
                            </div>
                            <div className='row justify-content-between d-flex align-items-center mt-3'>
                                <div className='flex-1 text-start'>
                                    <p className='mb-0'>{row.therapy_name}</p>
                                </div>
                                <div className='flex-1 text-end'>
                                    <p className='mb-0'>{row.date}</p>
                                </div>
                            </div>

                        </div>
                    ))
                ) : (
                    <div className="card mobile-card mt-3">
                        <h6 className='mb-0 card-content'>No logs found</h6>
                    </div>
                )}
            </div>
            <div className='footer-pad'>
                <MobileFooter />
            </div>
        </div>
    )
}

export default MobileSessionsFeedback
