import React, {useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import {TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Swal from "sweetalert2";
import { compressImg } from "../../../../ImageCompressor";
import { getAccount, updateAccount } from '../../../../Api';

function EditAccount() {
    const navigate = useNavigate();
    const {account_code} = useParams();
    const fileIp = React.useRef(null);
    const [img, setImg] = React.useState('');
    const [imgFile, setImgFile] = React.useState(null);
    const [empObj, setEmpObj] = React.useState({
        business_name: "",
        email: "",
        notification_email: "",
        phone: "",
        website: "",
        address: "",
        city: "",
        state: "",
        country: "",
        account_code: "",
        pic_name: "",
        pic_phone: "",
        pic_email: "",
        validity_start_date: "",
        validity_end_date: "",
        logo: "",
        no_of_licenses: ""
    })

    const handleReset = () => {
        if(fileIp.current) {
            fileIp.current.value = "";
            fileIp.current.type = "text";
            fileIp.current.type = "file";
        }
    };

    const handleFile = (event) => {
        console.log(event);
        setImg('https://placehold.co/250x100');
        const file = event.target.files[0];
        // console.log('Before compression', file.size)
        let splitfile = file.name;
        let extension = splitfile.split('.').pop();
        if(extension === 'png' || extension === 'PNG' || extension === 'jpg' || extension === 'JPG' || extension === 'jpeg' || extension === 'JPEG') {
            console.log('valid file');
            var reader = new FileReader();
            if(event.target.files[0]){
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e)=>{
                    setImg(e.target.result);
                }
                compressImg(event.target.files[0]).then(img => {
                    setImgFile(img);
                    // console.log('After compression', img.size)
                });
            }
        }else{
            Swal.fire({
                text: 'Invalid file format. Only .png, .jpg files are allowed',
                icon: 'warning',
                heightAuto: false
            })
            return
        }
    }

    const submit = async () => {
        let emalval = new RegExp(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,4}$"
        );
        if(!empObj.business_name){
            Swal.fire({
                text: 'Please provide Business name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.pic_name){
            Swal.fire({
                text: 'Pic Name is required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if (!empObj.no_of_licenses) {
          Swal.fire({
            text: "Please enter no of licenses!",
            icon: "warning",
            showConfirmButton: false,
            heightAuto: false,
            timer: 1500,
          });
        } else if (empObj.pic_email && !emalval.test(empObj.pic_email)) {
          Swal.fire({
            text: "Please enter valid PIC Email",
            icon: "warning",
            showConfirmButton: false,
            heightAuto: false,
            timer: 1500,
          });
        } else {
          let formData = new FormData();
          for (let val of Object.keys(empObj)) {
            if (val === "logo" && !imgFile) {
            } else if (val === "logo" && imgFile) {
              formData.append(val, imgFile);
            } else if (val === "email") {
              formData.append(val, empObj[val].toLowerCase());
            } else {
              formData.append(val, empObj[val]);
            }
          }
          let apiData = await updateAccount(formData, empObj.account_code);
          console.log(apiData);
          if (apiData.status === "S") {
            Swal.fire({
              text: "Account updated successfully",
              icon: "success",
              heightAuto: false,
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              navigate("/admin/manage-accounts");
            });
          }
        }
    }

    useEffect(() => {
      (async () => {
        let apiData = await getAccount(account_code);
        console.log(apiData);
        if(apiData.status === "S"){
            if(!apiData.result_info.logo){
                setImg('https://placehold.co/250x100');
            }
            setEmpObj(apiData.result_info);
        }else{
            setImg('https://placehold.co/250x100');
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
      })()
    }, [account_code, navigate])
    
    return (
      <div className="editContainer">
        <h5 className="page-heading">Edit Account</h5>
        <div className="card manage-card">
          <div className="row mb-3">
            <div className="col-12 text-center">
              {/* <h5 className="mb-3"><strong>Logo</strong></h5> */}
              {!imgFile && (
                <img
                  src={
                    empObj.logo
                      ? "https://impaxify.com/api/files/cdc/account_logos/" +
                        empObj.logo
                      : img
                  }
                  alt="IMPAXIFY - Software-for-child-development-centers"
                  style={{ maxHeight: "100px", maxWidth: "100%" }}
                />
              )}
              {imgFile && (
                <img
                  src={img}
                  alt="IMPAXIFY - Software-for-child-development-centers"
                  style={{ maxHeight: "100px", maxWidth: "100%" }}
                />
              )}
              <br />
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .webp"
                hidden
                ref={fileIp}
                onClick={handleReset}
                onChange={(ev) => {
                  handleFile(ev);
                }}
              />
              <button
                className="btn btn-dark px-4 my-3"
                onClick={() => {
                  fileIp.current.click();
                }}
              >
                Upload
              </button>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Business Name"
                variant="outlined"
                autoComplete="off"
                required
                value={empObj.business_name || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      business_name: value,
                    });
                  }
                }}
              />
            </div>

            <div className="col-12 col-lg-6 mb-3">
              <TextField
                disabled
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                autoComplete="off"
                required
                value={empObj.email || ""}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                disabled
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Mobile Number"
                variant="outlined"
                autoComplete="off"
                required
                value={empObj.phone || ""}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="w-100 cust-violet-input-field"
                  label="Validity Start Date*"
                  value={dayjs(empObj.validity_start_date) || ""}
                  onChange={(ev) => {
                    let date = dayjs(ev.$d).format("YYYY-MM-DD");
                    setEmpObj({ ...empObj, validity_start_date: date });
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="w-100 cust-violet-input-field"
                  label="Validity End Date*"
                  value={dayjs(empObj.validity_end_date) || ""}
                  onChange={(ev) => {
                    let date = dayjs(ev.$d).format("YYYY-MM-DD");
                    setEmpObj({ ...empObj, validity_end_date: date });
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Website"
                variant="outlined"
                autoComplete="off"
                value={empObj.website || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      website: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="City"
                variant="outlined"
                autoComplete="off"
                value={empObj.city || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      city: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="State"
                variant="outlined"
                autoComplete="off"
                value={empObj.state || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      state: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Country"
                variant="outlined"
                autoComplete="off"
                value={empObj.country || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      country: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                rows={3}
                multiline
                id="outlined-basic"
                label="Address"
                variant="outlined"
                autoComplete="off"
                value={empObj.address || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      address: value,
                    });
                  }
                }}
              />
            </div>

            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Person in charge / PIC Name*"
                variant="outlined"
                autoComplete="off"
                value={empObj.pic_name || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      pic_name: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                type="number"
                id="outlined-basic"
                label="No of Licenses*"
                variant="outlined"
                autoComplete="off"
                value={empObj.no_of_licenses || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  if (!isNaN(value)) {
                    setEmpObj({ ...empObj, no_of_licenses: value });
                  }
                }}
                min="0"
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="PIC Phone"
                variant="outlined"
                autoComplete="off"
                value={empObj.pic_phone || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const mobilePattern =
                    /^\+?(\d{1,4})?[-.\s]?(\d{0,3})[-.\s]?(\d{0,4})[-.\s]?(\d{0,4})$/;
                  if (value && mobilePattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      phone: value,
                    });
                  } else if (!value) {
                    setEmpObj({ ...empObj, pic_phone: "" });
                  }
                }}
                maxLength={15}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="PIC Email"
                variant="outlined"
                autoComplete="off"
                value={empObj.pic_email || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const emailPattern = /^[a-zA-Z0-9._%+-]+(@[a-zA-Z0-9.-]*)?$/;
                  if (value && emailPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      email: value,
                    });
                  } else if (!value) {
                    setEmpObj({ ...empObj, pic_email: "" });
                  }
                }}
              />
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn cancelButton"
              onClick={() => {
                submit();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    );
}

export default EditAccount
