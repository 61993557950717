import { ArrowBack } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect } from 'react'
import Swal from 'sweetalert2';
import { getAccount, updateAccount } from '../../Api';
import { compressImg } from '../../ImageCompressor';
import { useNavigate } from 'react-router-dom';

function MobileAdminProfile() {
    const navigate = useNavigate();
    const fileIp = React.useRef(null);
    const [img, setImg] = React.useState('');
    const [imgFile, setImgFile] = React.useState(null);
    const [empObj, setEmpObj] = React.useState({
        business_name: "",
        email: "",
        notification_email: "",
        phone: "",
        website: "",
        address: "",
        city: "",
        state: "",
        country: "",
        account_code: "",
        pic_name: "",
        pic_phone: "",
        pic_email: "",
        validity_start_date: "",
        validity_end_date: "",
        logo: ""
    })
    const handleReset = () => {
        if (fileIp.current) {
            fileIp.current.value = "";
            fileIp.current.type = "text";
            fileIp.current.type = "file";
        }
    };

    const handleFile = (event) => {
        console.log(event);
        setImg('https://placehold.co/250x100');
        const file = event.target.files[0];
        // console.log('Before compression', file.size)
        let splitfile = file.name;
        let extension = splitfile.split('.').pop();
        if (extension === 'png' || extension === 'PNG' || extension === 'jpg' || extension === 'JPG' || extension === 'jpeg' || extension === 'JPEG') {
            console.log('valid file');
            var reader = new FileReader();
            if (event.target.files[0]) {
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e) => {
                    setImg(e.target.result);
                }
                compressImg(event.target.files[0]).then(img => {
                    setImgFile(img);
                    // console.log('After compression', img.size)
                });
            }
        } else {
            Swal.fire({
                text: 'Invalid file format. Only .png, .jpg files are allowed',
                icon: 'warning',
                heightAuto: false
            })
            return
        }
    }
    const submit = async () => {
        console.log(empObj);
        if (!empObj.business_name) {
            Swal.fire({
                text: 'Business Name is required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (empObj.business_name > 255) {
            Swal.fire({
                text: 'Business name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (empObj.website > 255) {
            Swal.fire({
                text: 'Website should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (empObj.city > 255) {
            Swal.fire({
                text: 'City name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (empObj.state > 255) {
            Swal.fire({
                text: 'State should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (empObj.country > 255) {
            Swal.fire({
                text: 'Country name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let formData = new FormData();
            for (let val of Object.keys(empObj)) {
                if (val === "logo" && !imgFile) {
                } else if (val === "logo" && imgFile) {
                    formData.append(val, imgFile);
                } else if (val === "email") {
                    formData.append(val, empObj[val].toLowerCase());
                } else {
                    formData.append(val, empObj[val]);
                }
            }
            let apiData = await updateAccount(formData, empObj.account_code);
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Profile updated successfully',
                    icon: 'success',
                    heightAuto: false,
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    navigate('/app/dashboard');
                })
            }
        }
    }


    useEffect(() => {
        (async () => {
            const userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let apiData = await getAccount(userData.account_code);
            console.log(apiData);
            if (apiData.status === "S") {
                if (!apiData.result_info.logo) {
                    setImg('https://placehold.co/250x100');
                }
                setEmpObj(apiData.result_info);
            } else {
                setImg('https://placehold.co/250x100');
                if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                    Swal.fire({
                        text: "Please login with your credentials",
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        localStorage.clear();
                        navigate('/app/login');
                    })
                }
                if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                    Swal.fire({
                        text: "Please login with your credentials",
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        localStorage.clear();
                        navigate('/app/login');
                    })
                }
            }
        })()
    }, [navigate])
    const handleGoBack = () => {
        navigate('/app/morepage'); // Equivalent to history.goBack()
    };
    return (
      <div className="profilepage">
        <div className="bg-color">
          <div className="mb-3">
            <ArrowBack
              className="text-white"
              style={{ cursor: "pointer" }}
              onClick={handleGoBack}
            />
          </div>
          <div className="text-center">
            <h5 className="page-heading text-white mb-2">Profile</h5>
          </div>
        </div>
        <div className="contentContainer mt-3">
          <div className="row mb-3">
            <div className="col-12 text-center">
              {!imgFile && (
                <img
                  src={
                    empObj.logo
                      ? "https://impaxify.com/api/files/cdc/account_logos/" +
                        empObj.logo
                      : img
                  }
                  alt="IMPAXIFY - Software-for-child-development-centers"
                  style={{ maxHeight: "100px", maxWidth: "100%" }}
                />
              )}
              {imgFile && (
                <img
                  src={img}
                  alt="IMPAXIFY - Software-for-child-development-centers"
                  style={{ maxHeight: "100px", maxWidth: "100%" }}
                />
              )}
              <br />
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .webp"
                hidden
                ref={fileIp}
                onClick={handleReset}
                onChange={(ev) => {
                  handleFile(ev);
                }}
              />
              <button
                className="btn cancelButton px-4 my-3"
                onClick={() => {
                  fileIp.current.click();
                }}
              >
                Upload
              </button>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 "
                id="outlined-basic"
                label="Business Name"
                variant="outlined"
                autoComplete="off"
                required
                value={empObj.business_name || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      business_name: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                disabled
                className="w-100"
                id="outlined-basic"
                label="Mobile Number"
                variant="outlined"
                autoComplete="off"
                required
                value={empObj.phone || ""}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                disabled
                className="w-100"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                autoComplete="off"
                required
                value={empObj.email || ""}
              />
            </div>
            <div className="col-12 col-lg-6 mb-5">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="w-100"
                  label="Validity End Date*"
                  value={dayjs(empObj.validity_end_date) || ""}
                  disabled
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Website"
                variant="outlined"
                autoComplete="off"
                value={empObj.website || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      website: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                rows={3}
                multiline
                id="outlined-basic"
                label="Address"
                variant="outlined"
                autoComplete="off"
                value={empObj.address || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      address: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="City"
                variant="outlined"
                autoComplete="off"
                value={empObj.city || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      city: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="State"
                variant="outlined"
                autoComplete="off"
                value={empObj.state || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      state: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Country"
                variant="outlined"
                autoComplete="off"
                value={empObj.country || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      country: value,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="text-center d-flex fixed-bottom bg-white py-3 px-4">
            <button
              className="btn createButton flex-1"
              onClick={() => {
                submit();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    );
}

export default MobileAdminProfile
