import React from 'react';
import './website.scss';

function TermsandConditions() {

  const [openMenu, setOpenMenu] = React.useState(false)

  return (
    <div className="website">
      <header>
          <nav className="navbar navbar-expand-lg navbar-fixed-top">
            <div className="container-fluid px-0">
              <a className="navbar-brand" href="./">
              <img className='logo' src={require('../../assets/logo-3.png')} alt="IMPAXIFY - Software-for-child-development-centers" />
              </a>
              <button className="navbar-toggler" onClick={() => {setOpenMenu(!openMenu)}} type="button" aria-controls="navbarNavDropdown" aria-expanded={openMenu}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={openMenu ? 'collapse navbar-collapse justify-content-lg-end align-items-lg-center show':'collapse navbar-collapse justify-content-lg-end'} id="navbarNavDropdown">
                <ul className="navbar-nav align-items-center">
                  <li className="header-menu" id="menus">
                    <a href="./#home">Home</a>
                  </li>
                  <li className="header-menu" id="menus">
                    <a href="./#how-it-works">How it works</a>
                  </li>
                  <li className="header-menu" id="menus">
                    <a href="./#benefits">Benefits</a>
                  </li>
                  
                  <li className="header-menu" id="menus">
                    <a href="./#contact_section">Contact</a>
                  </li>
                  <li className="header-menu" id="menus">
                      <a href="./#contact_section"><button className="btn btn-large btn-demo rounded-pill px-4">FREE DEMO</button></a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
      </header>
      {/* Home Section */}
      <section className="banner-section d-flex align-items-center h-100 justify-content-center">
            <h3 className="banner-heading mb-0">Terms and Conditions</h3>
      </section>
      {/* How it works */}
      <section className="privacy-section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam corrupti quam possimus pariatur maiores quibusdam esse nam at consequuntur doloribus!</p>
                    <h4 className='privacy-heading fw-700 d-block'>Lorem Ipsum Dolor</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores fugiat adipisci libero sit soluta quidem.</p>
                    <p><span className='fw-700'>Lorem Ipsum:</span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos quod eaque animi ut, repudiandae at?</p>
                    <p><span className='fw-700'>Lorem Ipsum:</span> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique officiis necessitatibus, itaque quisquam reiciendis eius..</p>
                    <p><span className='fw-700'>Lorem Ipsum:</span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, suscipit modi officiis perferendis consequuntur expedita.</p>
                    <h4 className='privacy-heading fw-700 d-block'>Lorem Ipsum Dolor Sit Amet</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing:</p>
                    <ul>
                        <li>Lorem ipsum dolor sit amet.</li>
                        <li>Lorem ipsum dolor sit amet consectetur.</li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
                        <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis?</li>
                    </ul>
                    <h4 className='privacy-heading fw-700 d-block'>Lorem Ipsum</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing:</p>
                    <ul>
                        <li>Lorem ipsum dolor sit amet.</li>
                        <li>Lorem ipsum dolor sit amet consectetur.</li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
                        <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis?</li>
                    </ul>
                    <h4 className='privacy-heading fw-700 d-block'>Lorem Ipsum</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio architecto iste nesciunt vitae rerum temporibus cupiditate!</p>
                    
                    <h4 className='privacy-heading fw-700 d-block'>Lorem Ipsum</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio architecto iste nesciunt vitae rerum temporibus cupiditate!</p>

                    <h4 className='privacy-heading fw-700 d-block'>Lorem Ipsum</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio architecto iste nesciunt vitae rerum temporibus cupiditate!</p>

                    <h4 className='privacy-heading fw-700 d-block'>Contact Us</h4>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:lorem@impaxivesolutions.com" target='_blank' className="link-color" rel='noreferrer'>lorem@impaxivesolutions.com</a></p>
                </div>
            </div>
        </div>
      </section>
      {/* Footer */}
      <footer className="bg-dark text-white" id="footer">
        <div className="container-fluid">
          <div className="row align-items-center">
                <div className="col-12 col-lg-3 text-lg-start text-center text-large pe-lg-0">
                  <div id="menu">
                      <p className="mb-0"><a className="link-color text-white" href="./terms-and-conditions">Terms & Conditions</a></p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 text-center text-small p-lg-0 pb-sm-3">
                    <div id="menu">
                        <p className="mb-0 text-white">Copyright &copy; 2025 <a className="link-color text-white" href="https://www.impaxivesolutions.com/" target='_blank' rel='noreferrer'>Impaxive Solutions</a></p>
                    </div>
                </div>
                <div className="col-12 col-lg-3 text-white text-lg-end text-center text-large pe-lg-0">
                    <div id="menu">
                        <p className="mb-0"><a className="link-color text-white" href="./privacy-policy">Privacy Policy</a></p>
                    </div>
                </div>
          </div>
        </div>
      </footer>
      {/* <a className="scroll-top-arrow bg-orange scroll-top-home" href="javascript:void(0);"><i class="ti-arrow-up"></i></a> */}
    </div>
  )
}

export default TermsandConditions