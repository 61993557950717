import { ArrowBack } from '@mui/icons-material';
import "./editsessions.scss"
import { Checkbox, ListItemText, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import {
  updateHomeProtocol, deleteTherapyLog,
  getTherapyLogInfo,
} from "../../../Api";
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

function MobileEditSessions() {
    const navigate = useNavigate();
    const { role } = useOutletContext();
    const [isRole, setIsRole] = useState(null);
    const { therapy_log_id } = useParams();
    const [content, setContent] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const fetchTherapylogs = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserInfo(userData);
        let userRole = JSON.parse(localStorage.getItem('role'));
        setIsRole(userRole);
        let apiData = await getTherapyLogInfo(therapy_log_id);
        if (apiData.status === "S") {
            apiData.result_info.activity_names = apiData.result_info.activity_names.filter((activity) => activity !== null);
            setContent(apiData.result_info);
        }
    }
    const updateProtocol = async () => {
        let data = {
            home_workedon: content.home_workedon,
            home_observations: content.home_observations
        }
        let apiRes = await updateHomeProtocol(data, content.therapy_log_id);
        if (apiRes.status === "S") {
            Swal.fire({
                text: 'Session feedback updated successfully!',
                icon: 'success',
                showConfirmButton: false,
                // timer: 3000
            }).then(() => {
                navigate('/app/sessions-feedback');
            });
        }
    };
    const delSession = therapy_log_id => {
        Swal.fire({
            text: 'Are you sure you want to delete?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async res => {
            if (res.isConfirmed) {
                let apiRes = await deleteTherapyLog(therapy_log_id);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: 'Session feedback deleted successfully!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        // fetchData();
                        navigate('/app/sessions-feedback');
                    });
                }
            }
        });
    };
    useEffect(() => {
        fetchTherapylogs();
    }, [therapy_log_id]);

    const handleGoBack = () => {
        navigate(-1); // Equivalent to history.goBack()
    };
    return (
      <div className="mobile-editsessions">
        <div className="bg-color flex-column">
          <div className="mb-3">
            <ArrowBack
              className="text-white"
              style={{ cursor: "pointer" }}
              onClick={handleGoBack}
            />
          </div>
          <div className="text-center">
            <h5 className="page-heading text-white mb-2">Session Feedback</h5>
          </div>
        </div>
        {content && (
          <div className="contentContainer mt-3">
            <div className="row">
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  className="w-100 cust-violet-input-field"
                  id="outlined-basic"
                  label="Date"
                  variant="outlined"
                  autoComplete="off"
                  required
                  value={content.date}
                  disabled
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  className="w-100 cust-violet-input-field"
                  id="outlined-basic"
                  label="Student name"
                  variant="outlined"
                  autoComplete="off"
                  required
                  value={content.student_name}
                  disabled
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  className="w-100 cust-violet-input-field"
                  id="outlined-basic"
                  label="Therapy"
                  variant="outlined"
                  autoComplete="off"
                  required
                  value={content.therapy_name}
                  disabled
                />
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <TextField
                  className="w-100 cust-violet-input-field"
                  id="outlined-basic"
                  label="Goal"
                  variant="outlined"
                  autoComplete="off"
                  required
                  value={content.goal_name}
                  disabled
                />
              </div>
              <div className="col-12 mb-3">
                {content.activity_names &&
                  content.activity_names.length > 0 && (
                    <h6>
                      <strong>Tasks</strong>
                    </h6>
                  )}
                {content.activity_names &&
                  content.activity_names.length > 0 &&
                  content.activity_names.map((activity) => (
                    <div
                      key={"activity-" + activity.activity_id}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox disabled checked={true} />
                      <ListItemText primary={activity} />
                    </div>
                  ))}
              </div>
              {content && content.comments && (
                <div className="col-12 mb-3">
                  <TextField
                    className="w-100 cust-violet-input-field"
                    multiline
                    rows={4}
                    id="outlined-basic"
                    label="Comments"
                    variant="outlined"
                    autoComplete="off"
                    required
                    value={content.comments}
                    disabled
                  />
                </div>
              )}
              <div className="col-12 mb-3">
                {content.audio_file_path && (
                  <>
                    <h6 className="mb-2">
                      <strong>Recorded Audio</strong>
                    </h6>
                    <audio
                      className="w-100 my-2"
                      controls
                      controlsList="nodownload"
                      src={
                        "https://impaxify.com/api/files/cdc/audio/" +
                        content.audio_file_path
                      }
                    />
                  </>
                )}
                {(content.home_workedon || content.home_observations) &&
                  role !== "4" && (
                    <>
                      <h6 className="mb-3">
                        <strong>Home Protocols</strong>
                      </h6>
                      {content.home_workedon && (
                        <>
                          <h6 className="mb-2">Actions</h6>
                          <TextField
                            className="w-100 cust-violet-input-field mt-1"
                            id="work-today"
                            label=""
                            variant="outlined"
                            autoComplete="off"
                            required
                            multiline
                            minRows={4}
                            value={content.home_workedon}
                            disabled
                          />
                        </>
                      )}
                      {content.home_observations && (
                        <>
                        <h6 className="mb-2 mt-2">Observations</h6>
                        <TextField
                          className="w-100 cust-violet-input-field mt-1"
                          id="observations"
                          label=""
                          variant="outlined"
                          autoComplete="off"
                          required
                          multiline
                          minRows={4}
                          value={content.home_observations}
                          disabled
                        />
                        </>
                      )}
                    </>
                  )}
                {role === "4" && (
                  <>
                    <h6 className="mb-3">
                      <strong>Home Protocols</strong>
                    </h6>
                    <h6 className="mb-2">Actions</h6>
                    <TextField
                      className="w-100 cust-violet-input-field mt-1"
                      id="work-today"
                      label=""
                      variant="outlined"
                      autoComplete="off"
                      required
                      multiline
                      minRows={4}
                      value={content.home_workedon}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        const invalidPattern =
                          /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                        if (!invalidPattern.test(value)) {
                          setContent({
                            ...content,
                            home_workedon: value,
                          });
                        }
                      }}
                    />
                    <h6 className="mb-2 mt-2">Observations</h6>
                    <TextField
                      className="w-100 cust-violet-input-field mt-1"
                      id="observations"
                      label=""
                      variant="outlined"
                      autoComplete="off"
                      required
                      multiline
                      minRows={4}
                      value={content.home_observations}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        const invalidPattern =
                          /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                        if (!invalidPattern.test(value)) {
                          setContent({
                            ...content,
                            home_observations: value,
                          });
                        }
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            {+role === 3 && (
              <div className="row buttonsRow d-flex px-3">
                <button
                  className="cancelButton flex-1 me-2"
                  onClick={() => delSession(content.therapy_log_id)}
                >
                  Delete
                </button>
                <button
                  className="createButton flex-1"
                  onClick={() => {
                    navigate("/app/sessions-feedback");
                  }}
                >
                  Back
                </button>
              </div>
            )}
            {+role === 4 && (
              <div className="row buttonsRow d-flex px-3">
                <button
                  className="cancelButton flex-1 me-2"
                  onClick={() => updateProtocol()}
                >
                  Update
                </button>
                <button
                  className="createButton flex-1"
                  onClick={() => {
                    navigate("/app/sessions-feedback");
                  }}
                >
                  Back
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
}

export default MobileEditSessions
