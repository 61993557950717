import React, { useEffect, useState } from 'react';
import './website.scss';
import { Check } from '@mui/icons-material';
import { MdFeedback, MdHealthAndSafety, MdLocalActivity, MdLocationPin, MdMedicalServices, MdNotifications, MdOutlineGroup, MdPerson, MdTrackChanges, MdUpdate } from 'react-icons/md';
import { FaChartLine, FaClock, FaRegEnvelopeOpen, FaUsers, FaUsersGear, FaCommentDots, FaHospitalUser, FaPeopleArrows, FaBullseye, FaClipboardCheck} from "react-icons/fa6";
import Swal from 'sweetalert2';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { GiAchievement, GiChecklist } from 'react-icons/gi';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { RiStethoscopeLine } from 'react-icons/ri';
import { HiOutlineClipboardList } from 'react-icons/hi';

function Website() {

  const [openMenu, setOpenMenu] = React.useState(false);
  const scriptUrl = 'https://script.google.com/macros/s/AKfycbwCKYERBbpUvWfDIYJDEiPiTnSe-tFHKNvyBhXt56KCLtFGE3bpDeOd5xLGTm9Zx2dY/exec'
    
 const validateForm = () => {
    let nameDemo = document.forms["form-contact"]["Name"].value;
    let emailDemo = document.forms["form-contact"]["Email"].value;
    let phoneNumber = document.forms["form-contact"]["Phone"].value;
    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    let phoneNumberPattern = /^\+?([0-9]{2,4})\)?[-. ]?([0-9]{3,4})[-. ]?([0-9]{3,4})[-. ]?([0-9]{2,4})$/;
    if(nameDemo === "") {
            Swal.fire({
            text: 'Please enter your Name',
            icon: 'warning',
            confirmButtonText: 'ok'
            })
            return false
    }else if(emailDemo === ""){
            Swal.fire({
            text: 'Please enter Email Address',
            icon: 'warning',
            confirmButtonText: 'ok'
            })
            return false
    }else if(!emailPattern.test(emailDemo)){
            Swal.fire({
            text: 'Please enter valid Email Address',
            icon: 'warning',
            confirmButtonText: 'ok'
            })
            return false
    }else if(phoneNumber === ""){
            Swal.fire({
            text: 'Please enter Phone Number',
            icon: 'warning',
            confirmButtonText: 'ok'
            })
            return false
    }else if(!phoneNumberPattern.test(phoneNumber)){
            Swal.fire({
            text: 'Please enter valid Phone Number',
            icon: 'warning',
            confirmButtonText: 'ok'
            })
            return false
    }else {
            return true
    }
}

 

  const submitform = () => {
    const form = document.querySelector('#contact-form')
    const btn = document.querySelector('#contact-us-button')
    if(validateForm()){
        btn.disabled = true
        btn.innerHTML = "Sending..."
        fetch(scriptUrl, { method: 'POST', body: new FormData(form) }).then(response => {
            if(response){
                form.reset();
                btn.disabled = false
                btn.innerHTML = 'Send Message'
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'ok'
                })
            }
        }).catch(error => {
            btn.disabled = false
            btn.innerHTML = 'Send Message'
            Swal.fire({
                title: 'Error!',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'ok'
            })
        });
    }
  }
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  const [activeSection, setActiveSection] = useState('home');
  
  useEffect(() => {
   const handleScroll = () => {
     const sections = ['home','how-it-works', 'benefits', 'contact_section'];
     const scrollPosition = window.scrollY;

     sections.forEach((section, index) => {
       const element = document.getElementById(section);

       if (element) {
         const offsetTop = element.offsetTop - 100; // Adjust this value as needed
         const offsetBottom = offsetTop + element.offsetHeight;

         if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
           setActiveSection(section);
         }
       }
     });
     console.log(sections, "scrolled here");
   };

   
     window.addEventListener('scroll', handleScroll);
     return () => {
       window.removeEventListener('scroll', handleScroll);
     };
   }, []);


  return (
    <div className="website">
      <header>
          <nav className="navbar navbar-expand-lg navbar-fixed-top">
            <div className="container-fluid px-0">
              <a className="navbar-brand" href="./">
                <img className='logo' src={require('../../assets/logo-3.png')} alt="IMPAXIFY - Software-for-child-development-centers" />
              </a>
              <button className="navbar-toggler" onClick={() => {setOpenMenu(!openMenu)}} type="button" aria-controls="navbarNavDropdown" aria-expanded={openMenu}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={openMenu ? 'collapse navbar-collapse justify-content-lg-end align-items-lg-center show':'collapse navbar-collapse justify-content-lg-end'} id="navbarNavDropdown">
                <ul className="navbar-nav align-items-center">
                    <li className={`header-menu ${activeSection === 'home' ? 'active' : ''}`} id="menus">
                        <a href="#home">Home</a>
                    </li>
                    <li className={`header-menu ${activeSection === 'how-it-works' ? 'active' : ''}`} id="menus">
                        <a href="#how-it-works">How it works</a>
                    </li>
                    <li className={`header-menu ${activeSection === 'benefits' ? 'active' : ''}`} id="menus">
                        <a href="#benefits">Benefits</a>
                    </li>
                    
                    <li className={`header-menu ${activeSection === 'contact_section' ? 'active' : ''}`} id="menus">
                        <a href="#contact_section">Contact</a>
                    </li>
                    <li className={`header-menu ${activeSection === 'contact_section' ? 'active' : ''}`} id="menus">
                        <a href="#contact_section">
                        <button className="btn btn-large createButton rounded-pill px-4">FREE DEMO</button>
                        </a>
                    </li>
                </ul>
              </div>
            </div>
          </nav>
      </header>
      {/* Home Section */}
      <section className="section-padding" id="home">
        <div className="container-fluid wow fadeIn"> 
          <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6 text-center mt-0 pt-0 wow fadeInRight d-lg-none d-block" data-wow-delay=".2s">
                <img className="home_banner_image" src={require('../../assets/impaxify-child-therapy-center-softwares.jpg')} alt="IMPAXIFY - Software-for-child-development-centers" />
            </div>
            <div className="col-12 col-lg-6 text-black">
                <h3 className="banner-heading">Transforming Therapy Centers with Technology</h3>
                <ul className="list-style-12">
                    
                    <li className="alt-font text-medium"><Check /> Increased Revenue</li>
                    <li className="alt-font text-medium"><Check /> Parent Engagement Made Easy</li>
                    <li className="alt-font text-medium"><Check /> Transparent Progress</li>
                    <li className="alt-font text-medium"><Check /> Operational Excellence</li>
                </ul>
                <a href="#contact_section" className="btn btn-large createButton rounded-pill wow fadeInUp mt-2" data-wow-delay="0.2s">
                    Book Free demo
                </a>
            </div>
            <div className="col-12 col-lg-6 text-center mt-4 mt-lg-0 wow fadeInRight d-lg-block d-none" data-wow-delay=".2s">
                <img className="home_banner_image" src={require('../../assets/impaxify-child-therapy-center-softwares.jpg')} alt="IMPAXIFY - Software-for-child-development-centers" />
            </div>
          </div>
        </div>
      </section>
      {/* How it works */}
      <section className="section-padding" id="how-it-works">
        <div className="container-fluid">
            <div className="col-12 text-center">
                <h5 className="section-heading">How it works?</h5>
            </div>
            <div className="row">
                <div className="col-12 col-lg-3 col-md-6 last-paragraph-no-margin margin-20px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card h-100">
                        <div className="bg-choose">
                            <FaPeopleArrows className="featurecard-icon" />
                            {/* <span>01</span> */}
                        </div>
                        {/* <span className="text-large">Time Efficiency</span> */}
                        <p className='featurecard-desc'>The therapy center owner defines their center's services, therapists, and patients in the app.</p>

                    </div>
                </div>
                <div className="col-12 col-lg-3 col-md-6 last-paragraph-no-margin margin-20px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card h-100">
                        <div className="bg-choose">
                            <MdTrackChanges className="featurecard-icon" />
                        </div>
                        {/* <span className="text-large">Lower Churn Rate</span> */}
                        <p className='featurecard-desc'>The therapist or center owner defines goals for each patient.</p>

                    </div>
                </div>
                <div className="col-12 col-lg-3 col-md-6 last-paragraph-no-margin margin-20px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card h-100">
                        <div className="bg-choose">
                            <RiStethoscopeLine className="featurecard-icon" />
                        </div>
                        {/* <span className="text-large">Enhanced Communication</span> */}
                        <p className='featurecard-desc'>The therapist conducts sessions to meet the goals and updates the session progress in the app, along with home exercises for the parent.</p>

                    </div>
                </div>
                <div className="col-12 col-lg-3 col-md-6 last-paragraph-no-margin margin-20px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card h-100">
                        <div className="bg-choose">
                            <HiOutlineClipboardList className="featurecard-icon" />
                        </div>
                        {/* <span className="text-large">Learning Plan & Goal Setting</span> */}
                        <p className='featurecard-desc'>The parent reviews the session feedback on a daily basis and performs the home exercises as defined by the therapist.</p>

                    </div>
                </div>
            </div>
        </div>
      </section>
      {/* How it works */}
      <section className="section-padding" id="benefits">
        <div className="container-fluid">
            <div className="col-12 text-center">
                <h5 className="section-heading">Benefits</h5>
            </div>
            <div className="row">
                <div className="col-12 col-lg-4 col-md-6 last-paragraph-no-margin margin-20px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card h-100">
                        <div className="bg-choose">
                            <span>01</span>
                        </div>
                        <span className="text-large">Time Efficiency</span>
                        <p className='featurecard-desc'>Automates administrative tasks, reducing time spent on non-clinical work, allowing therapists to focus more on patient care and improving overall productivity.</p>

                    </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6 last-paragraph-no-margin margin-20px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card h-100">
                        <div className="bg-choose">
                            <span>02</span>
                        </div>
                        <span className="text-large">Lower Churn Rate</span>
                        <p className='featurecard-desc'>With improved communication and better tracking of progress, parents experience higher satisfaction, leading to reduced churn rates.</p>

                    </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6 last-paragraph-no-margin margin-20px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card h-100">
                        <div className="bg-choose">
                            <span>03</span>
                        </div>
                        <span className="text-large">Enhanced Communication</span>
                        <p className='featurecard-desc'>Clear, written records of sessions, goals, and home protocols strengthen parent-therapist relationships, fostering trust and loyalty.</p>

                    </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6 last-paragraph-no-margin margin-20px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card h-100">
                        <div className="bg-choose">
                            <span>04</span>
                        </div>
                        <span className="text-large">Learning Plan & Goal Setting</span>
                        <p className='featurecard-desc'>Therapists can set personalized learning plans and goals for each child before the session, ensuring a structured approach to therapy and better outcomes.</p>

                    </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6 last-paragraph-no-margin margin-20px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card h-100">
                        <div className="bg-choose">
                            <span>05</span>
                        </div>
                        <span className="text-large">Real-time Session Updates</span>
                        <p className='featurecard-desc'>Therapists can instantly update parents on session details through the app, keeping them informed and engaged.</p>

                    </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6 last-paragraph-no-margin margin-20px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card h-100">
                        <div className="bg-choose">
                            <span>06</span>
                        </div>
                        <span className="text-large">Home Protocol Tracking</span>
                        <p className='featurecard-desc'>Parents can easily follow and track the home protocols set by therapists, improving consistency in care.</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
      
      {/* Features Section */}
      <section className="section-padding d-none" id="features">
        <div className="container-fluid wow fadeIn" data-wow-delay="0.4s"> 
            <div className="col-12 text-center">
                <h5 className="section-heading">Features</h5>
            </div>
            <div className="row">
                <div className="col-12 col-lg-4 col-md-6 last-paragraph-no-margin wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card">
                        <div className="bg-choose">
                            <MdMedicalServices className='featurecard-icon' />
                        </div>
                        <span className="text-large">Create & Manage Therapies</span>
                    </div>
                </div>
                 <div className="col-12 col-lg-4 col-md-6" data-wow-delay=".5s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card">
                        <div className="bg-choose">
                            <MdOutlineGroup className='featurecard-icon' />
                        </div>
                        <span className="text-large">Create & Manage Therapists</span>
                    </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6">
                    <div className="text-center feature-card">
                        <div className="bg-choose">
                            <MdPerson className='featurecard-icon' />
                        </div>
                        <span className="text-large">Create & Manage Students</span>
                    </div>                    
                </div>
                <div className="col-12 col-lg-4 col-md-6">
                    <div className="text-center feature-card">
                        <div className="bg-choose">
                            <MdLocalActivity className='featurecard-icon' />
                        </div>
                        <span className="text-large">Set Goals & Activities</span>
                    </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6">
                    <div className="text-center feature-card">
                        <div className="bg-choose">
                            <MdUpdate className='featurecard-icon' />
                        </div>
                        <span className="text-large">Real-time Progress updates</span>
                    </div>                    
                </div>
                <div className="col-12 col-lg-4 col-md-6">
                    <div className="text-center feature-card">
                        <div className="bg-choose">
                            <MdNotifications className='featurecard-icon' />
                        </div>
                        <span className="text-large">Fee Reminders & Announcements</span>
                    </div>                    
                </div>
            </div>
        </div>
      </section>
      
      <section className="padding-50px-all sm-padding-30px-all bg-extra-dark-gray" id="contact_section">
        <div className="container-fluid p-0">
            <h5 className="section-heading text-white text-center">Contact Us</h5>
            <form id="contact-form" onSubmit={(event) => {
                event.preventDefault();
                submitform()
            }} name="form-contact" className="col-12 p-0 mx-auto">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 text-center">
                        <input type="text" name="Name" id="name" placeholder="Name*" className="input-border-bottom" autoComplete="off" />
                        <input type="text" name="Email" id="email" placeholder="E-mail*" className="input-border-bottom" autoComplete="off" />
                        <input type="text" name="Phone" id="phone" placeholder="Phone Number*" className="input-border-bottom" autoComplete="off" />
                        <textarea name="Message" id="message" placeholder="Message" className="input-border-bottom"></textarea>
                        <button id="contact-us-button" type='submit' className="btn btn-large createButton rounded-pill mt-3">send message</button>
                    </div>
                </div>
            </form>
        </div>     
      </section>
        <section className="section-padding" id="features">
            <div className="container-fluid p-0" data-wow-delay="0.4s"> 
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-4 text-center">
                         <FaCommentDots className="contact-icon mb-3" />
                         {/* <FontAwesomeIcon icon={faCommentDots} className="contact-icon mb-3" /> */}
                        <div className='text-center justify-content-center'>
                            <p>
                                <a className='blue-color-link' href="tel:+91 9154171047">+91 9154171047</a>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 text-center">
                        <MdLocationPin className="contact-icon mb-3" />
                        <div className='text-center justify-content-center'>
                            <p className="text-extra-dark-gray width-100 sm-margin-10px-bottom mx-auto">
                                5th Floor, Shanta Sriram Building, <br /> PSR Prime Towers, Beside DLF BUILDING, <br /> DLF Cyber City, Indira Nagar, Gachibowli, <br /> Hyderabad, Telangana 500032
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 text-center">
                        <FaRegEnvelopeOpen className='mb-3 contact-icon' />
                        <div className='text-center justify-content-center'>
                            <p>
                                <a className='blue-color-link' href="mailto:info@impaxivesolutions.com">info@impaxivesolutions.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer className="bg-dark text-white" id="footer">
        <div className="container-fluid">
          <div className="row align-items-center">
                {/* <div className="col-12 col-lg-3 text-lg-start text-center text-large pe-lg-0">
                  <div id="menu">
                      <p className="mb-0"><a className="link-color text-white" href="./terms-and-conditions">Terms & Conditions</a></p>
                  </div>
                </div> */}
                <div className="col-12 col-lg-6 text-lg-start text-center text-small p-lg-0 pb-sm-3">
                    <div id="menu">
                        <p className="mb-0 text-white">Copyright &copy; 2025 <a className="link-color text-white" href="https://www.impaxivesolutions.com/" target='_blank' rel='noreferrer'>Impaxive Solutions</a></p>
                    </div>
                </div>
                <div className="col-12 col-lg-6 text-white text-lg-end text-center text-large pe-lg-0">
                    <div id="menu">
                        <p className="mb-0"><a className="link-color text-white" href="./privacy-policy">Privacy Policy</a></p>
                    </div>
                </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Website