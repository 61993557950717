import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./profile.scss";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { getStudentInfo, getTherapies, updateStudent } from "../../Api";

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  width: "100%",
  color: "#000",
  "&.Mui-focused": {
    color: "#000",
  },
}));
const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#8267ed",
  },
}));

function StudentProfile({ userData }) {
  const navigate = useNavigate();
  const [selectedTherapies, setSelectedTherapies] = useState([]);
  const [therapies, setTherapies] = useState([]);

  const [userInfo, setUserInfo] = useState(null);
  const [content, setContent] = React.useState(null);

  const fetchData = async () => {
    let userData = JSON.parse(localStorage.getItem("userData") || "{}");
    setUserInfo(userData);

    if (userData.student_correl_id) {
      let apiData = await getStudentInfo(userData.student_correl_id);
      if (apiData.status === "S") {
        setContent(apiData.result_info);
        setSelectedTherapies(
          apiData.result_info.enrolled_therapies.map(
            (therapy) => therapy.therapy_id
          )
        );
      } else {
        setContent(null);
      }
    }

    let therapiesData = await getTherapies(userData.account_code);
    if (therapiesData.status === "S") {
      setTherapies(therapiesData.result_info);
    } else {
      setTherapies([]);
    }
  };
  const update = async (student_id) => {
    let userData = JSON.parse(localStorage.getItem("userData") || "{}");
    console.log(content);
    console.log(selectedTherapies);
    if (!content.student_name) {
      Swal.fire({
        text: "Please enter the Student name",
        icon: "warning",
        showConfirmButton: true,
      });
      return;
    } else if (!content.student_name.length > 255) {
      Swal.fire({
        text: "Student name should be less than 255 characters",
        icon: "warning",
        showConfirmButton: true,
      });
      return;
    } else if (!content.parent_name) {
      Swal.fire({
        text: "Please enter the Parent name",
        icon: "warning",
        showConfirmButton: true,
      });
      return;
    } else if (!content.parent_name.length > 255) {
      Swal.fire({
        text: "Parent name should be less than 255 characters",
        icon: "warning",
        showConfirmButton: true,
      });
      return;
    }
    let apiData = await updateStudent(
      {
        account_code: userData.account_code,
        student_correl_id: content.student_correl_id,
        student_name: content.student_name,
        parent_name: content.parent_name,
        parent_mobile: content.parent_mobile,
        parent_email: content.parent_email,
        enrolled_therapies: selectedTherapies.join(","),
        fees: content.fees,
        notes: content.notes,
      },
      student_id
    );
    console.log(apiData);
    if (apiData.status === "S") {
      Swal.fire({
        text: "Profile updated successfully",
        showConfirmButton: false,
        icon: "success",
        timer: 3000,
      }).then(() => {
        navigate("/app/dashboard");
      });
    } else if (apiData.status === "E" && apiData.result_code === 404) {
      Swal.fire({
        text: "Account not found",
        showConfirmButton: false,
        icon: "warning",
        timer: 3000,
      }).then(() => {
        navigate("/app/dashboard");
      });
    } else if (
      apiData.status === "F" &&
      apiData.message === "Unauthorized - Missing token"
    ) {
      Swal.fire({
        text: "Please login with your credentials",
        icon: "warning",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        localStorage.clear();
        navigate("/app/login");
      });
    } else if (
      apiData.status === "F" &&
      apiData.message === "Unauthorized - Invalid token"
    ) {
      Swal.fire({
        text: "Please login with your credentials",
        icon: "warning",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        localStorage.clear();
        navigate("/app/login");
      });
    } else {
      Swal.fire({
        text: JSON.stringify(apiData),
        showConfirmButton: false,
        icon: "warning",
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {content && (
        <div className="createContainer">
          <h5 className="page-heading">Profile</h5>
          <div className="row mb-lg-3">
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Name"
                variant="outlined"
                autoComplete="off"
                required
                value={content.student_name || ""}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setContent({
                      ...content,
                      student_name: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth *"
                  className="w-100 cust-violet-input-field"
                  defaultValue={dayjs(content.student_dob)}
                  value={dayjs(content.student_dob)}
                  onChange={(ev) => {
                    let date = ev?.$d;
                    setContent({
                      ...content,
                      student_dob: dayjs(date).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="row mb-lg-3">
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Parent name"
                variant="outlined"
                autoComplete="off"
                required
                value={content.parent_name}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setContent({
                      ...content,
                      parent_name: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100"
                id="outlined-basic"
                label="Parent mobile"
                variant="outlined"
                autoComplete="off"
                required
                disabled
                value={content.parent_mobile}
              />
            </div>
          </div>
          <div className="row mb-lg-3">
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100"
                id="outlined-basic"
                label="Parent email"
                variant="outlined"
                autoComplete="off"
                required
                disabled
                value={content.parent_email}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100"
                id="outlined-basic"
                label="Fees"
                variant="outlined"
                autoComplete="off"
                required
                value={content.fees}
                disabled
              />
            </div>
          </div>
          <div className="row mb-lg-3">
            <div className="col-12 mb-3">
              <FormControl fullWidth required>
                <CustomInputLabel id="demo-multiselect-label" disabled>
                  Enrolled Therapies
                </CustomInputLabel>
                <CustomSelect
                  labelId="demo-multiselect-label"
                  id="demo-multiselect"
                  multiple
                  value={selectedTherapies}
                  onChange={(event) => setSelectedTherapies(event.target.value)}
                  renderValue={(selected) =>
                    selected
                      .map((id) => {
                        const therapy = therapies.find(
                          (therapy) => therapy.therapy_id === id
                        );
                        return therapy ? therapy.therapy_name : "";
                      })
                      .join(", ")
                  }
                  label="Enrolled Therapies"
                  disabled
                >
                  {therapies.map((therapy) => (
                    <MenuItem
                      key={therapy.therapy_id}
                      value={therapy.therapy_id}
                    >
                      <Checkbox
                        checked={selectedTherapies.includes(therapy.therapy_id)}
                      />
                      <ListItemText primary={therapy.therapy_name} />
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </div>
          </div>
          {/* <div className="row mb-3">
                        <div className="col-12 mb-3">
                            <TextField
                                className="w-100 cust-violet-input-field"
                                id="outlined-basic"
                                label="Notes"
                                variant="outlined"
                                multiline
                                rows={2}
                                autoComplete="off"
                                value={content.notes} onChange={(ev) => {
                                    setContent({ ...content, notes: ev.target.value })
                                }}
                            />
                        </div>
                    </div> */}
          <div className="row buttonsRow">
            <button
              className="createButton"
              onClick={() => {
                update(content.student_id);
              }}
            >
              Update
            </button>
            {/* <button className='cancelButton' onClick={() => { navigate('/app/manage-students') }}>Cancel</button> */}
          </div>
        </div>
      )}
    </>
  );
}

export default StudentProfile;
