import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./resetpassword.scss";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";
import { changePassword } from "../../Api";
import { ArrowBack } from "@mui/icons-material";

function ResetPassword() {
  const navigate = useNavigate();
  const [userObj, setUserObj] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const submit = async () => {
    let formData = { ...userObj };
    if (!formData.oldPassword) {
      Swal.fire({
        text: "Old password required!",
        showConfirmButton: false,
        icon: "warning",
        timer: 3000,
      });
      return;
    } else if (!userObj.newPassword) {
      Swal.fire({
        text: "New password required!",
        showConfirmButton: false,
        icon: "warning",
        timer: 3000,
      });
      return;
    } else if (userObj.oldPassword === userObj.newPassword) {
      Swal.fire({
        text: "New password should not match with old password!",
        showConfirmButton: false,
        icon: "warning",
        timer: 3000,
      });
      return;
    } else if (!userObj.confirmPassword) {
      Swal.fire({
        text: "Confirm password required!",
        showConfirmButton: false,
        icon: "warning",
        timer: 3000,
      });
      return;
    } else if (userObj.confirmPassword !== userObj.newPassword) {
      Swal.fire({
        text: "New password should match with confirm password!",
        showConfirmButton: false,
        icon: "warning",
        timer: 3000,
      });
      return;
    } else {
      let userData = JSON.parse(localStorage.getItem("userData") || "{}");
      const correl_id = localStorage.getItem("correl_id");
      let resData = await changePassword({ ...formData, correl_id });
      if (resData.status === "S") {
        Swal.fire({
          text: "Password changed, Redirecting to Login!",
          showConfirmButton: false,
          icon: "success",
          timer: 3000,
        }).then(() => {
          navigate("/app/login");
        });
      } else if (resData.status === "E" && resData.result_code === 404) {
        Swal.fire({
          text: "User not found",
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
        return;
      } else if (resData.status === "E" && resData.result_code === 401) {
        Swal.fire({
          text: "Invalid Old password",
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
        return;
      } else if (resData.status === "E" && resData.result_code === 400) {
        Swal.fire({
          text: "New password and confirm password does not match",
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
        return;
      } else if (
        resData.status === "F" &&
        resData.message === "Unauthorized - Missing token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      } else if (
        resData.status === "F" &&
        resData.message === "Unauthorized - Invalid token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      } else {
        Swal.fire({
          text: JSON.stringify(resData),
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
        return;
      }
    }
  };
  const handleGoBack = () => {
    navigate(-1); // Equivalent to history.goBack()
  };
  return (
    <div className="createContainer">
      <div className="row justify-content-center px-0 mx-0">
        <div className="col-12 px-0">
          <h5 className="page-heading">Reset Password</h5>
        </div>
      </div>
      <div className="row ">
        <div className="col-12">
          <TextField
            id="outlined-basic"
            label="Old Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            className="mb-4 w-100 cust-violet-input-field"
            autoComplete="off"
            value={userObj.oldPassword}
            onChange={(ev) => {
              const value = ev.target.value;
              const invalidPattern = /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
              if (!invalidPattern.test(value)) {
                setUserObj({
                  ...userObj,
                  oldPassword: value,
                });
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="text-black"
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-basic"
            label="New Password"
            variant="outlined"
            type={showPassword1 ? "text" : "password"}
            className="mb-4 w-100 cust-violet-input-field"
            autoComplete="off"
            value={userObj.newPassword}
            onInput={(ev) => {
              const value = ev.target.value;
              const invalidPattern = /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
              if (!invalidPattern.test(value)) {
                setUserObj({
                  ...userObj,
                  newPassword: value,
                });
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="text-black"
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword1(!showPassword1);
                    }}
                    edge="end"
                  >
                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-basic"
            label="Confirm Password"
            variant="outlined"
            type={showPassword2 ? "text" : "password"}
            className="mb-4 w-100 cust-violet-input-field"
            autoComplete="off"
            value={userObj.confirmPassword}
            onInput={(ev) => {
              const value = ev.target.value;
              const invalidPattern = /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
              if (!invalidPattern.test(value)) {
                setUserObj({
                  ...userObj,
                  confirmPassword: value,
                });
              }
            }}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                submit();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="text-black"
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword2(!showPassword2);
                    }}
                    edge="end"
                  >
                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="text-center">
            <button
              className="btn createButton px-4 text-uppercase"
              onClick={submit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
