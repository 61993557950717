import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./createtherapy.scss";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import { createTherapy } from "../../../Api";

function CreateTherapy() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [content, setContent] = React.useState({
    therapy_name: "",
  });
  const submit = async () => {
    if (!content.therapy_name) {
      Swal.fire({
        text: "Please provide Therapy name!",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else if (content.therapy_name.length > 255) {
      Swal.fire({
        text: "Therapy name should be less than 255 characters",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
      });
    } else {
      let data = { ...content };
      let userData = JSON.parse(localStorage.getItem("userData") || "{}");
      data.account_code = userData.account_code;
      let apiData = await createTherapy({ ...data });
      console.log(apiData);
      if (apiData.status === "S") {
        Swal.fire({
          text: "Therapy created successfully",
          showConfirmButton: false,
          icon: "success",
          timer: 3000,
        }).then(() => {
          navigate("/app/manage-therapies");
        });
      }
      if (apiData.status === "E" && apiData.result_code === 201) {
        Swal.fire({
          text: "Therapy with same name already exists",
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
      } else if (apiData.status === "E") {
        Swal.fire({
          text: apiData.message,
          showConfirmButton: false,
          icon: "warning",
          timer: 3000,
        });
      }
      if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Missing token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      }
      if (
        apiData.status === "F" &&
        apiData.message === "Unauthorized - Invalid token"
      ) {
        Swal.fire({
          text: "Please login with your credentials",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          localStorage.clear();
          navigate("/app/login");
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      let userData = JSON.parse(localStorage.getItem("userData") || "{}");
      setUserInfo(userData);
    })();
  }, []);

  return (
    <div className="createContainer">
      <h5 className="page-heading">Create Therapy</h5>
      <div className="row mb-3">
        <div className="col-12 mb-3">
          <TextField
            className="w-100 cust-violet-input-field"
            id="outlined-basic"
            label="Name"
            variant="outlined"
            autoComplete="off"
            required
            value={content.therapy_name}
            onChange={(ev) => {
              const value = ev.target.value;
              const invalidPattern = /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
              if (!invalidPattern.test(value)) {
                setContent({
                  ...content,
                  therapy_name: value,
                });
              }
            }}
          />
        </div>
      </div>
      <div className="row buttonsRow">
        <button
          className="createButton me-3"
          onClick={() => {
            submit();
          }}
        >
          Create
        </button>
        <button
          className="cancelButton"
          onClick={() => {
            navigate("/app/manage-therapies");
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default CreateTherapy;
