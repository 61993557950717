import React from 'react';
import './website.scss';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {

  const [openMenu, setOpenMenu] = React.useState(false)

  return (
    <div className="website">
      <header>
          <nav className="navbar navbar-expand-lg navbar-fixed-top">
            <div className="container-fluid px-0">
              <a className="navbar-brand" href="./">
              <img className='logo' src={require('../../assets/logo-3.png')} alt="IMPAXIFY - Software-for-child-development-centers" />
              </a>
              <button className="navbar-toggler" onClick={() => {setOpenMenu(!openMenu)}} type="button" aria-controls="navbarNavDropdown" aria-expanded={openMenu}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={openMenu ? 'collapse navbar-collapse justify-content-lg-end align-items-lg-center show':'collapse navbar-collapse justify-content-lg-end'} id="navbarNavDropdown">
                <ul className="navbar-nav align-items-center">
                  <li className="header-menu" id="menus">
                    {/* <a href="../#home">Home</a> */}
                    <Link to="/#home">Home</Link>
                  </li>
                  <li className="header-menu" id="menus">
                    {/* <a href="./#features">Features</a> */}
                    <Link to="/#how-it-works">How it works</Link>
                  </li>
                  <li className="header-menu" id="menus">
                    {/* <a href="/#benefits">Benefits</a> */}
                    <Link to="/#benefits">Benefits</Link>
                  </li>
                  
                  <li className="header-menu" id="menus">
                    {/* <a href="./#contact_section">Contact</a> */}
                    <Link to="/#contact_section">Contact</Link>
                  </li>
                  <li className="header-menu" id="menus">
                  <Link to="/#contact_section"><button className="btn btn-large createButton rounded-pill px-4">FREE DEMO</button></Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
      </header>
      {/* Home Section */}
      <section className="banner-section d-flex align-items-center h-100 justify-content-center">
            <h3 className="banner-heading mb-0">Privacy Policy</h3>
      </section>
      {/* How it works */}
      <section className="privacy-section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <p>Effective Date: <strong>22-May-2024</strong> </p>
                    <h4 className='privacy-heading fw-700 d-block'>Introduction</h4>
                    <p>Impaxify ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our services.</p>

                    <h4 className='privacy-heading fw-700 d-block'>Information We Collect</h4>
                    <p><span className='fw-500'>Personal Information:</span> Name, email address, phone number, and other contact details.</p>
                    <p><span className='fw-500'>Usage Data:</span> Information on how you use our website and services.</p>
                    <p><span className='fw-500'>Cookies and Tracking Technologies:</span> To improve user experience and track website performance.</p>
                    
                    <h4 className='privacy-heading fw-700 d-block'>How We Use Your Information</h4>
                    <p><span className='fw-500'>To Provide Services:</span> Personalize and enhance your experience.</p>
                    <p><span className='fw-500'>Communication:</span> Send updates, newsletters, and marketing materials.</p>
                    <p><span className='fw-500'>Improvement:</span> Analyze usage to improve our services.</p>
                    
                    <h4 className='privacy-heading fw-700 d-block'>Information Sharing</h4>
                    <p><span className='fw-500'>With Third Parties:</span> Service providers who assist us in operating our website and conducting our business.</p>
                    <p><span className='fw-500'>Legal Requirements:</span> To comply with legal obligations or protect our rights.</p>

                    <h4 className='privacy-heading fw-700 d-block'>Data Security</h4>
                    <p>We implement robust security measures to protect your data. However, no method of transmission over the internet is 100% secure.</p>
                    
                    <h4 className='privacy-heading fw-700 d-block'>Your Rights</h4>
                    <p><span className='fw-500'>Access and Update:</span> Request access to or update your personal information.</p>
                    <p><span className='fw-500'>Opt-Out:</span> Unsubscribe from marketing communications.</p>

                    <h4 className='privacy-heading fw-700 d-block'>Changes to This Privacy Policy</h4>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                    <h4 className='privacy-heading fw-700 d-block'>Contact Us</h4>
                    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@impaxivesolutions.com" target='_blank' className="link-color" rel='noreferrer'>info@impaxivesolutions.com</a></p>

                    <h4 className='privacy-heading fw-700 d-block'>Credits</h4>
                    <p>Images which we used are sourced from <a href="https://www.freepik.com/" target='_blank' className="link-color" rel='noreferrer'>Freepik.com</a></p>
                    <p>Icons are sourced from  <a href="https://fontawesome.com/" target='_blank' className="link-color" rel='noreferrer'>Font Awesome</a></p>
                    <p><a href="https://storyset.com/people" target='_blank' className="link-color" rel='noreferrer'>People illustrations by Storyset</a></p>
                    <p>Logo icon is created using <a href="https://www.canva.com/" target='_blank' className="link-color" rel='noreferrer'>canva</a> premium account </p>
                </div>
            </div>
        </div>
      </section>
      {/* Footer */}
      <footer className="bg-dark text-white" id="footer">
        <div className="container-fluid">
          <div className="row align-items-center">
                {/* <div className="col-12 col-lg-3 text-lg-start text-center text-large pe-lg-0">
                  <div id="menu">
                      <p className="mb-0"><a className="link-color text-white" href="./terms-and-conditions">Terms & Conditions</a></p>
                  </div>
                </div> */}
                <div className="col-12 col-lg-6 text-lg-start text-center text-small p-lg-0 pb-sm-3">
                    <div id="menu">
                        <p className="mb-0 text-white">Copyright &copy; 2025 <a className="link-color text-white" href="https://www.impaxivesolutions.com/" target='_blank' rel='noreferrer'>Impaxive Solutions</a></p>
                    </div>
                </div>
                <div className="col-12 col-lg-6 text-white text-lg-end text-center text-large pe-lg-0">
                    <div id="menu">
                        <p className="mb-0"><a className="link-color text-white" href="./privacy-policy">Privacy Policy</a></p>
                    </div>
                </div>
          </div>
        </div>
      </footer>
      {/* <a className="scroll-top-arrow bg-orange scroll-top-home" href="javascript:void(0);"><i class="ti-arrow-up"></i></a> */}
    </div>
  )
}

export default PrivacyPolicy