import React, { useEffect, useState } from 'react'
import "./editstudent.scss"
import Swal from 'sweetalert2';
import { deleteStudent, getStudentInfo, getTherapies, updateStudent } from '../../../Api';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField, styled } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    width: '100%',
    color: '#000',
    '&.Mui-focused': {
        color: '#000',
    },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8267ed',
    },
}));

const ColoredCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.main,
    '&.Mui-checked': {
        color: '#8267ed',
        borderColor: '#8267ed',
    },
}));
function MobileEditStudent() {
    const navigate = useNavigate();
    const { student_correl_id } = useParams();
    const [selectedTherapies, setSelectedTherapies] = useState([]);
    const [therapies, setTherapies] = useState([]);

    const [userInfo, setUserInfo] = useState(null);

    const [content, setContent] = React.useState(null);

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserInfo(userData);

        let apiData = await getStudentInfo(student_correl_id);
        if (apiData.status === "S") {
            setContent(apiData.result_info);
            setSelectedTherapies(apiData.result_info.enrolled_therapies.map((therapy) => therapy.therapy_id))
        } else {
            setContent(null)
        }

        let therapiesData = await getTherapies(userData.account_code);
        if (therapiesData.status === "S") {
            setTherapies(therapiesData.result_info);
        } else {
            setTherapies([])
        }
    }
    const update = async (student_id) => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        console.log(content);
        console.log(selectedTherapies);
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if (!content.student_name) {
            Swal.fire({
                text: 'Please provide Student name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.student_name.length > 255) {
            Swal.fire({
                text: 'Student name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.parent_email && !emalval.test(content.parent_email)) {
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.parent_name) {
            Swal.fire({
                text: 'Please provide Parent name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.parent_name.length > 255) {
            Swal.fire({
                text: 'Parent name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.parent_email.length > 255) {
            Swal.fire({
                text: 'Parent email should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.student_dob) {
            Swal.fire({
                text: 'Please select Date of Birth',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.parent_mobile) {
            Swal.fire({
                text: 'Please provide Mobile number',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.parent_mobile.length > 255) {
            Swal.fire({
                text: 'Mobile Number should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (selectedTherapies.length === 0) {
            Swal.fire({
                text: 'Please select therapies',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.fees) {
            Swal.fire({
                text: 'Please enter fees',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let apiData = await updateStudent({
                account_code: userData.account_code,
                student_correl_id: content.student_correl_id,
                student_name: content.student_name,
                parent_name: content.parent_name,
                parent_mobile: content.parent_mobile,
                parent_email: content.parent_email,
                enrolled_therapies: selectedTherapies.join(","),
                fees: content.fees,
                notes: content.notes,
            }, student_id);
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Student updated successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-students')
                })
            } else if (apiData.status === "E" && apiData.result_code === 404) {
                Swal.fire({
                    text: 'Student not found',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-students')
                })
            } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else {
                Swal.fire({
                    text: JSON.stringify(apiData),
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
        }
    }
    const delStudent = (student_id) => {
        Swal.fire({
            text: `Are you sure you want to delete the student?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let apiRes = await deleteStudent(student_id);
                console.log(apiRes);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: `Student Deleted`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        fetchData();
                        navigate('/app/manage-students');
                    });
                }
            }
        })
    }

    useEffect(() => {
        fetchData();
    }, [])
    const handleGoBack = () => {
        navigate('/app/manage-students'); // Equivalent to history.goBack()
    };
    return (
      <>
        {content && (
          <div className="mobile-editStudent">
            <div className="bg-color">
              <div className="mb-3">
                <ArrowBack
                  className="text-white"
                  style={{ cursor: "pointer" }}
                  onClick={handleGoBack}
                />
              </div>
              <div className="text-center">
                <h5 className="page-heading text-white mb-2">Edit Student</h5>
              </div>
            </div>
            <div className="contentContainer px-4">
              <div className="row mb-lg-3">
                <div className="col-12 col-lg-6 mb-3 mt-2">
                  <TextField
                    className="w-100 cust-violet-input-field"
                    id="outlined-basic"
                    label="Student Name"
                    variant="outlined"
                    autoComplete="off"
                    required
                    value={content.student_name || ""}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      const invalidPattern =
                        /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                      if (!invalidPattern.test(value)) {
                        setContent({
                          ...content,
                          student_name: value,
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Birth *"
                      className="w-100 cust-violet-input-field"
                      defaultValue={dayjs(content.student_dob)}
                      value={dayjs(content.student_dob)}
                      onChange={(ev) => {
                        let date = ev?.$d;
                        setContent({
                          ...content,
                          student_dob: dayjs(date).format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="row mb-lg-3">
                <div className="col-12 col-lg-6 mb-3">
                  <TextField
                    className="w-100 cust-violet-input-field"
                    id="outlined-basic"
                    label="Parent Name"
                    variant="outlined"
                    autoComplete="off"
                    required
                    value={content.parent_name}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      const invalidPattern =
                        /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                      if (!invalidPattern.test(value)) {
                        setContent({
                          ...content,
                          parent_name: value,
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-3">
                  <TextField
                    className="w-100"
                    id="outlined-basic"
                    label="Mobile"
                    variant="outlined"
                    autoComplete="off"
                    required
                    disabled
                    value={content.parent_mobile}
                  />
                </div>
              </div>
              <div className="row mb-lg-3">
                <div className="col-12 col-lg-6 mb-3">
                  <TextField
                    className="w-100"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    autoComplete="off"
                    required
                    disabled
                    value={content.parent_email}
                  />
                </div>
              </div>
              <div className="row mb-lg-3">
                <div className="col-12 mb-3">
                  <FormControl fullWidth required>
                    <CustomInputLabel id="demo-multiselect-label">
                      Enrolled Therapies
                    </CustomInputLabel>
                    <CustomSelect
                      labelId="demo-multiselect-label"
                      id="demo-multiselect"
                      multiple
                      value={selectedTherapies}
                      onChange={(event) =>
                        setSelectedTherapies(event.target.value)
                      }
                      renderValue={(selected) =>
                        selected
                          .map((id) => {
                            const therapy = therapies.find(
                              (therapy) => therapy.therapy_id === id
                            );
                            return therapy ? therapy.therapy_name : "";
                          })
                          .join(", ")
                      }
                      label="Enrolled Therapies"
                    >
                      {therapies.map((therapy) => (
                        <MenuItem
                          key={therapy.therapy_id}
                          value={therapy.therapy_id}
                        >
                          <ColoredCheckbox
                            checked={selectedTherapies.includes(
                              therapy.therapy_id
                            )}
                          />
                          <ListItemText primary={therapy.therapy_name} />
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                </div>
                <div className="col-12 col-lg-6 mb-3">
                  <TextField
                    className="w-100 cust-violet-input-field"
                    id="outlined-basic"
                    label="Fees"
                    variant="outlined"
                    autoComplete="off"
                    required
                    value={content.fees}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      if (!isNaN(value)) {
                        setContent({ ...content, fees: value });
                      }
                    }}
                    inputProps={{
                      min: 0,
                      maxLength: 9, // Set maxLength here
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 mb-5">
                  <TextField
                    className="w-100 cust-violet-input-field"
                    id="outlined-basic"
                    label="Notes"
                    variant="outlined"
                    multiline
                    rows={2}
                    autoComplete="off"
                    value={content.notes}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      const invalidPattern =
                        /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                      if (!invalidPattern.test(value)) {
                        setContent({ ...content, notes: value });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="buttonsRow d-flex px-3 fixed-bottom bg-white py-3">
                <button
                  className="cancelButton me-2 flex-1"
                  onClick={() => {
                    delStudent(content.student_id);
                  }}
                >
                  Delete
                </button>
                <button
                  className="createButton flex-1"
                  onClick={() => {
                    update(content.student_id);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
}

export default MobileEditStudent
