import React from 'react'
import { useNavigate } from 'react-router-dom';
import {TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Swal from "sweetalert2";
import { compressImg } from "../../../../ImageCompressor";
import { createAccountApi } from '../../../../Api';

function CreateAccount() {
    const navigate = useNavigate();
    const fileIp = React.useRef(null);
    const [img, setImg] = React.useState('https://placehold.co/250x100');
    const [empObj, setEmpObj] = React.useState({
        business_name: "",
        email: "",
        phone: "",
        pic_name: "",
        pic_phone: "",
        pic_email: "",
        website: "",
        address: "",
        city: "",
        state: "",
        country: "",
        account_code: "",
        validity_start_date: "",
        validity_end_date: "",
        logo: "",
        no_of_licenses: ""
    })

    const handleReset = () => {
        if(fileIp.current) {
            fileIp.current.value = "";
            fileIp.current.type = "text";
            fileIp.current.type = "file";
        }
    };

    const handleFile = (event) => {
        console.log(event);
        setImg('https://placehold.co/250x100');
        const file = event.target.files[0];
        console.log('Before compression', file.size)
        let splitfile = file.name;
        let extension = splitfile.split('.').pop();
        if(extension === 'png' || extension === 'PNG' || extension === 'jpg' || extension === 'JPG' || extension === 'jpeg' || extension === 'JPEG') {
            console.log('valid file');
            var reader = new FileReader();
            if(event.target.files[0]){
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e)=>{
                    setImg(e.target.result);
                }
                compressImg(event.target.files[0]).then(img => {
                    setEmpObj({...empObj, logo: img});
                    console.log('After compression', img.size)
                });
            }
        }else{
            Swal.fire({
                text: 'Invalid file format. Only .png, .jpg files are allowed',
                icon: 'warning',
                heightAuto: false
            })
            return
        }
    }

    const submit = async () => {
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'}
        });
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,4}$');
        if(!empObj.business_name){
            Swal.fire({
                text: 'Please provide Business name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.email){
            Swal.fire({
                text: 'Email required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!emalval.test(empObj.email)){
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.phone){
            Swal.fire({
                text: 'Mobile number required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.validity_start_date){
            Swal.fire({
                text: 'Validity start date required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if (!empObj.validity_end_date) {
          Swal.fire({
            text: "Validity end date required!",
            icon: "warning",
            showConfirmButton: false,
            heightAuto: false,
            timer: 1500,
          });
        } else if (!empObj.account_code) {
          Swal.fire({
            text: "Account code required!",
            icon: "warning",
            showConfirmButton: false,
            heightAuto: false,
            timer: 1500,
          });
        } else if (!empObj.pic_name) {
          Swal.fire({
            text: "Please enter PIC name!",
            icon: "warning",
            showConfirmButton: false,
            heightAuto: false,
            timer: 1500,
          });
        } else if (!empObj.no_of_licenses) {
          Swal.fire({
            text: "Please enter no of licenses!",
            icon: "warning",
            showConfirmButton: false,
            heightAuto: false,
            timer: 1500,
          });
        } else if (empObj.pic_email && !emalval.test(empObj.pic_email)) {
          Swal.fire({
            text: "Please enter valid PIC Email",
            icon: "warning",
            showConfirmButton: false,
            heightAuto: false,
            timer: 1500,
          });
        } else {
          let formData = new FormData();
          for (let val of Object.keys(empObj)) {
            if (val === "logo" && !empObj[val]) {
            } else if (val === "logo" && empObj[val]) {
              formData.append(val, empObj[val]);
            } else if (val === "email") {
              formData.append(val, empObj[val].toLowerCase());
            } else {
              formData.append(val, empObj[val]);
            }
          }
          for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
          }
          let apiData = await createAccountApi(formData);
          Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: { popup: "loader-swal" },
            timer: 2000,
          });
          if (apiData.status === "S") {
            Swal.fire({
              text: "Account created successfully",
              icon: "success",
              heightAuto: false,
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              navigate("/admin/manage-accounts");
            });
          }
          if (
            apiData.status === "F" &&
            apiData.message === "Unauthorized - Missing token"
          ) {
            Swal.fire({
              text: "Please login with your credentials",
              icon: "warning",
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              localStorage.clear();
              navigate("/app/login");
            });
          }
          if (
            apiData.status === "F" &&
            apiData.message === "Unauthorized - Invalid token"
          ) {
            Swal.fire({
              text: "Please login with your credentials",
              icon: "warning",
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              localStorage.clear();
              navigate("/app/login");
            });
          }
          if (
            apiData.status === "E" &&
            apiData.error === "Only image files are allowed!"
          ) {
            Swal.fire({
              text: "Please select valid image file",
              icon: "warning",
              showConfirmButton: false,
              timer: 3000,
            });
          }
          if (apiData.status === "F" && apiData.message === "Server error") {
            Swal.fire({
              text: apiData.errorDescription,
              icon: "warning",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
    }
    
    return (
      <div className="createContainer">
        <h5 className="page-heading mb-3">Create Account</h5>
        <div className="card manage-card">
          <div className="row mb-3">
            <div className="col-12 text-center">
              <h5 className="mb-3">
                <strong>Logo</strong>
              </h5>
              <img
                src={img}
                alt="IMPAXIFY - Software-for-child-development-centers"
                style={{ maxHeight: "100px", maxWidth: "100%" }}
              />
              <br />
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .webp"
                hidden
                ref={fileIp}
                onClick={handleReset}
                onChange={(ev) => {
                  handleFile(ev);
                }}
              />
              <button
                className="btn btn-dark px-4 my-3"
                onClick={() => {
                  fileIp.current.click();
                }}
              >
                Upload
              </button>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Business Name"
                variant="outlined"
                autoComplete="off"
                required
                value={empObj.business_name}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      business_name: value,
                    });
                  }
                }}
              />
            </div>

            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                autoComplete="off"
                required
                value={empObj.email}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const emailPattern = /^[a-zA-Z0-9._%+-]+(@[a-zA-Z0-9.-]*)?$/;
                  if (value && emailPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      email: value,
                    });
                  } else if (!value) {
                    setEmpObj({ ...empObj, email: "" });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Mobile Number"
                variant="outlined"
                autoComplete="off"
                required
                value={empObj.phone}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const mobilePattern =
                    /^\+?(\d{1,4})?[-.\s]?(\d{0,3})[-.\s]?(\d{0,4})[-.\s]?(\d{0,4})$/;
                  if (value && mobilePattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      phone: value,
                    });
                  } else if (!value) {
                    setEmpObj({ ...empObj, phone: "" });
                  }
                }}
                maxLength={15}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="w-100 cust-violet-input-field"
                  label="Validity Start Date*"
                  onChange={(ev) => {
                    let date = dayjs(ev.$d).format("YYYY-MM-DD");
                    setEmpObj({ ...empObj, validity_start_date: date });
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="w-100 cust-violet-input-field"
                  label="Validity End Date*"
                  onChange={(ev) => {
                    let date = dayjs(ev.$d).format("YYYY-MM-DD");
                    setEmpObj({ ...empObj, validity_end_date: date });
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Website"
                variant="outlined"
                autoComplete="off"
                value={empObj.website}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      website: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="City"
                variant="outlined"
                autoComplete="off"
                value={empObj.city}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      city: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="State"
                variant="outlined"
                autoComplete="off"
                value={empObj.state}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      state: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Country"
                variant="outlined"
                autoComplete="off"
                value={empObj.country}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      country: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Account Code"
                variant="outlined"
                autoComplete="off"
                required
                value={empObj.account_code}
                onChange={(ev) => {
                  let val = ev.target.value;
                  let regex = /[^a-zA-Z-]/gi;
                  setEmpObj({
                    ...empObj,
                    account_code: val.replace(regex, ""),
                  });
                }}
              />
            </div>
            <div className="col-12 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                rows={3}
                multiline
                id="outlined-basic"
                label="Address"
                variant="outlined"
                autoComplete="off"
                value={empObj.address}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      address: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="Person in charge / PIC Name*"
                variant="outlined"
                autoComplete="off"
                value={empObj.pic_name}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const invalidPattern =
                    /<script.*?>.*?<\/script>|[<>\\{}=:/""]/i;
                  if (!invalidPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      pic_name: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="No of Licenses*"
                variant="outlined"
                autoComplete="off"
                value={empObj.no_of_licenses}
                onChange={(ev) => {
                  const value = ev.target.value;
                  if (!isNaN(value)) {
                    setEmpObj({ ...empObj, no_of_licenses: value });
                  }
                }}
                min="0"
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="PIC Phone"
                variant="outlined"
                autoComplete="off"
                value={empObj.pic_phone}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const mobilePattern =
                    /^\+?(\d{1,4})?[-.\s]?(\d{0,3})[-.\s]?(\d{0,4})[-.\s]?(\d{0,4})$/;
                  if (value && mobilePattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      phone: value,
                    });
                  } else if (!value) {
                    setEmpObj({ ...empObj, pic_phone: "" });
                  }
                }}
                maxLength={15}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <TextField
                className="w-100 cust-violet-input-field"
                id="outlined-basic"
                label="PIC Email"
                variant="outlined"
                autoComplete="off"
                value={empObj.pic_email}
                onChange={(ev) => {
                  const value = ev.target.value;
                  const emailPattern = /^[a-zA-Z0-9._%+-]+(@[a-zA-Z0-9.-]*)?$/;
                  if (value && emailPattern.test(value)) {
                    setEmpObj({
                      ...empObj,
                      email: value,
                    });
                  } else if (!value) {
                    setEmpObj({ ...empObj, pic_email: "" });
                  }
                }}
              />
            </div>
          </div>
          <div className="row buttonsRow">
            <button
              className="createButton me-3"
              onClick={() => {
                submit();
              }}
            >
              Create
            </button>
            <button
              className="cancelButton"
              onClick={() => {
                navigate("/admin/manage-accounts");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
}

export default CreateAccount
